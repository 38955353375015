import { HiddenFacets, IRefinementDefinition, SearchBox } from '@watermarkchurch/react-instantsearch-components'
import algoliasearch from 'algoliasearch'
import * as React from 'react'
import { Configure, connectStats } from 'react-instantsearch-dom'
import WCC from 'wcc'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import AlgoliaSearch from '../algolia-search'
import { SearchFacet } from '../porch-search-facet'
import ConnectedSearchResults from './search-results'

interface IProps extends ITranslationsProvided {
  basePath?: string,
  podcastSource: PodcastSource,
  translations: {
    noResults: string,
    searchReset: string,
    searchBox: {
      placeholder: string,
    },
  },
  showFacets?: boolean,
  showSearch?: boolean,
  header?: string,
  limit?: number,
  showCount?: boolean,
  showHasMore?: boolean
}

const HitCount = ({ nbHits }: any) => <span className="porch-podcast-search__hits-count ml-3">({nbHits})</span>

const HitCountStat = connectStats(HitCount)

type PodcastSource = IAlgoliaPodcastSource

interface IAlgoliaPodcastSource {
  source: 'Algolia'
  index: string
  facetFilters?: IRefinementDefinition
  operator?: 'or' | 'and'
}

const autosuggestClient = algoliasearch(
  WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering',
  WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY  || 'server-rendering')

export class PorchPodcastSearch extends React.Component<IProps> {
  public render() {
    const {
      podcastSource,
      showFacets,
      showSearch,
      header,
      limit,
      showCount,
      showHasMore = false,
      basePath = typeof window === 'undefined' ? '/' : window.location.pathname,
    } = this.props

    return (
      <AlgoliaSearch
        indexName={podcastSource.index}
        widgetName="podcast-search"
        storeStateInQuery={true}
      >
        {podcastSource.facetFilters &&
          <HiddenFacets hiddenFacets={podcastSource.facetFilters}
            operator={podcastSource.operator} />}
        <div className="container">
          <div className={showFacets || showSearch ? 'row mb-4' : 'row'}>
            <div className="col-section-heading">
              {header && <h2 className="section-title">
                {header}
                {showCount ? (
                  <HitCountStat />
                ) : null}
              </h2>}
            </div>
          </div>
          <div className="row mb-4 porch-podcast-search__filters">
            {showSearch && (
              <>
                <div className="col-12 col-md-10">
                  <SearchBox defaultIndex={podcastSource.index}
                    autosuggestClient={autosuggestClient}
                    className="narrow porch-podcast-search__search-box"
                    searchAsYouType={false}
                    translations={this.props.translationsAt('searchBox') as { [key: string]: string }} />
                </div>
              </>
            )}
            {showFacets && (
              <>
                <div className="col-12 col-md-2">
                  <SearchFacet
                    attribute="date_year"
                    attributeTitle="Year"
                    sort="desc"
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="container pt-4">
          <Configure hitsPerPage={limit || 9} />
          <ConnectedSearchResults showHasMore={showHasMore} basePath={basePath} />
        </div>
      </AlgoliaSearch>
    )
  }
}

const ConnectedPorchPodcastSearch = withTranslations(PorchPodcastSearch)
export default ConnectedPorchPodcastSearch
