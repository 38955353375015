import { AlgoliaSearch as WrappedAlgoliaSearch, IInstantSearchProps } from '@watermarkchurch/react-instantsearch-components'
import * as React from 'react'
import WCC from 'wcc'

export type {IInstantSearchProps} from '@watermarkchurch/react-instantsearch-components'

export default class AlgoliaSearch extends React.Component<IInstantSearchProps> {

  public render() {
    const {children, ...props} = this.props

    return (
      <WrappedAlgoliaSearch
        apiKey={ WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY  || 'server-rendering' }
        appId={ WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering' }
        hosts={ WCC.algoliaProxy ? [WCC.algoliaProxy] : undefined }
        { ...props }
      >
        {children}
      </WrappedAlgoliaSearch>
    )
  }
}
