import { HiddenFacets, SearchBox } from '@watermarkchurch/react-instantsearch-components'
import algoliasearch from 'algoliasearch'
import * as React from 'react'
import { ClearRefinements, Configure } from 'react-instantsearch-dom'
import WCC from 'wcc'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { Stringable } from '../../util'
import AlgoliaSearch from '../algolia-search'
import ConnectedCurrentRefinements from '../regen-stories-list/current-refinements'
import ConnectedRefinementsDropdown from '../regen-stories-list/refinements-dropdown'
import ConnectedSearchResults from './search-results'

interface IProps extends ITranslationsProvided {
  blogSource: BlogSource,
  translations: {
    noResults: string,
    searchReset: string,
    blog_facet_select: {
      see_all_option: string,
    },
    searchBox: {
      placeholder: string,
    },
    clearRefinements: {
      reset: string,
    },
  },
  showFacets?: boolean
  header?: string
}

type BlogSource = IAlgoliaBlogSource

interface IAlgoliaBlogSource {
  source: 'Algolia'
  index: string
  facetFilters?: {
    [key: string]: Stringable[] | Stringable,
  }
  operator?: 'or' | 'and'
}

interface IState {
  hasFacets: boolean
}

const autosuggestClient = algoliasearch(
  WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering',
  WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY || 'server-rendering')

export class RegenResourcesList extends React.Component<IProps, IState> {
  public state: IState = {
    hasFacets: true,
  }

  public render() {
    const { blogSource, translations, translationsAt } = this.props
    const { hasFacets } = this.state

    return (
      <AlgoliaSearch
        indexName={blogSource.index}
        widgetName="regen-resources-list"
        storeStateInQuery={true}
      >
        <Configure hitsPerPage={6} />
        <HiddenFacets hiddenFacets={{ regen_result_type: 'Resources' }} />

        <div className="col-content-container col-content-offset px-0">
          <div className="d-md-flex justify-content-between mb-4">
            <div>
              <SearchBox defaultIndex={blogSource.index}
                autosuggestClient={autosuggestClient}
                className="narrow"
                searchAsYouType={false}
                translations={translationsAt('searchBox') as { [key: string]: string }} />
            </div>
            <div className="mt-3 mt-md-0">
              <ConnectedRefinementsDropdown
                attribute={'Post.tags.title'}
                translations={translations}
                hasFacets={hasFacets}
              />
            </div>
          </div>
          <div className={`d-md-flex justify-content-between ${hasFacets ? 'mb-5' : ''}`}>
            <div>
              <ConnectedCurrentRefinements />
            </div>
            <div className="mt-3 mt-md-0">
              <ClearRefinements translations={translationsAt('clearRefinements')} />
            </div>
          </div>
        </div>

        <ConnectedSearchResults />
      </AlgoliaSearch>
    )
  }

  public setHasFacets = (facetsPresent: boolean) => {
    if (facetsPresent !== this.state.hasFacets) {
      this.setState({ hasFacets: facetsPresent })
    }
  }
}

const ConnectedRegenResourcesList = withTranslations(RegenResourcesList)
export default ConnectedRegenResourcesList
