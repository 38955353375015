import { useEffect, useRef } from 'react'

/**
 * A hook to help us overcome the deficiencies of using setInterval with useEffect in React.
 *
 * @see https://www.joshwcomeau.com/snippets/react-hooks/use-interval/
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export function useInterval(callback: () => void, delay: number | null) {
  const intervalRef = useRef<number | undefined>(undefined)
  const savedCallback = useRef(callback)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => savedCallback.current()
    if (typeof delay === 'number') {
      intervalRef.current = setInterval(tick, delay)
      return () => clearInterval(intervalRef.current)
    }
  }, [delay])
  return intervalRef
}
