import React, { FunctionComponent, useState } from 'react'

interface IProps {
  initialSearch?: string
  onSearchChange: (newValue: string) => void
}

const SearchFilters: FunctionComponent<IProps> = ({ initialSearch = '', onSearchChange }) => {
  const [search, setSearch] = useState<string>(initialSearch)

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value)
    if (e.currentTarget.value === '') {
      handleReset()
    }
  }

  const handleReset = () => {
    setSearch('')
    onSearchChange('')
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    onSearchChange(search)

    return false
  }

  const clearClass = search === '' ? 'invisible' : ''

  return (
    <form onSubmit={handleSubmit} onReset={handleReset} className="search-filters">
      <div className="input-group">
        <div className="input-group-prepend">
          <button type="submit" title="Submit your search.">
            <i className="material-icons">search</i>
          </button>
        </div>
        <input
          type="search"
          className="form-control"
          value={search}
          onChange={handleChange}
          placeholder="Search"
          autoCorrect="false"
          autoComplete="false"
          spellCheck="false"
        />
        <div className="input-group-append">
          <button type="reset" title="Submit your search.">
            <i className={`material-icons ${clearClass}`}>clear</i>
          </button>
        </div>
      </div>
    </form>
  )
}

export default SearchFilters
