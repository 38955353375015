import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import WCC from 'wcc'

import { ICompositePage, ICompositePost } from '../../models/algolia/composite'

interface IProps {
  hit: Hit<ICompositePost | ICompositePage>
}

const SearchHit: React.FunctionComponent<IProps> = ({ hit }) => {
  const { title } = hit
  let slug
  let description
  let imageUrl

  if (hit.object_type === 'Post') {
    slug = hit.Post.slug
    description = hit.Post.summary
    imageUrl = hit.Post.hero_image_url
  } else if (hit.object_type === 'Page') {
    slug = hit.Page.slug
    description = hit.Page.description
    imageUrl =
      hit.Page.meta?.pageImageUrl ||
      hit.Page.hero?.image_url ||
      WCC.siteConfig.defaultSearchResultImageUrl
  }

  return (
    <div className="search-result">
      <a href={slug} className="row">
        <div className="col-12 col-md-4">
          <div className="image">
            <img alt='' src={imageUrl || undefined} />
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="details">
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </div>
      </a>
    </div>
  )
}

export default SearchHit
