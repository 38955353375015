import { HiddenFacets, IRefinementDefinition, SearchBox } from '@watermarkchurch/react-instantsearch-components'
import algoliasearch from 'algoliasearch'
import * as React from 'react'
import { Configure, connectStats } from 'react-instantsearch-dom'
import WCC from 'wcc'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import AlgoliaSearch from '../algolia-search'
import { SearchFacet } from '../porch-search-facet'
import ConnectedSearchResults from './search-results'

interface IProps extends ITranslationsProvided {
  mediaSource: MediaSource,
  speakers?: string[]
  translations: {
    noResults: string,
    searchReset: string,
    searchBox: {
      placeholder: string,
    },
  },
  showFacets?: boolean,
  header?: string,
  limit?: number,
  showCount?: boolean,
  showHasMore?: boolean
}

const HitCount = ({ nbHits }: any) => <span className="porch-media-search__hits-count ml-3">({nbHits})</span>

const HitCountStat = connectStats(HitCount)

type MediaSource = IAlgoliaMediaSource

interface IAlgoliaMediaSource {
  source: 'Algolia'
  index: string
  facetFilters?: IRefinementDefinition
  operator?: 'or' | 'and'
}

const autosuggestClient = algoliasearch(
  WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering',
  WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY  || 'server-rendering')

const defaultSpeakers = ['Timothy "TA" Ateek', 'David Marvin', 'Jonathan Pokluda', 'JD Rodgers', 'Josiah Jones']

export class PorchMediaSearch extends React.Component<IProps> {
  public render() {
    const { speakers, mediaSource, showFacets, header, limit, showCount, showHasMore = false } = this.props

    return (
      <AlgoliaSearch
        indexName={mediaSource.index}
        widgetName="media-search"
        storeStateInQuery={true}
      >
        {mediaSource.facetFilters &&
          <HiddenFacets hiddenFacets={mediaSource.facetFilters}
            operator={mediaSource.operator} />}
        <div className="container">
          <div className="row mb-4">
            <div className="col-section-heading">
              {header && <h2 className="section-title">
                {header}
                {showCount ? (
                  <HitCountStat />
                ) : null}
              </h2>}
            </div>
          </div>
          <div className="row mb-4 porch-media-search__filters">
            <div className="col-12 col-md-4">
              <SearchBox defaultIndex={mediaSource.index}
                autosuggestClient={autosuggestClient}
                className="narrow porch-media-search__search-box"
                searchAsYouType={false}
                translations={this.props.translationsAt('searchBox') as { [key: string]: string }} />
            </div>
            {showFacets && (
              <>
                <div className="col-12 col-md">
                  <SearchFacet
                    attribute="date_year"
                    attributeTitle="Year"
                    sort="desc"
                  />
                </div>
                <div className="col-12 col-md">
                  <SearchFacet
                    attribute="topics"
                    attributeTitle="Topics"
                  />
                </div>
                <div className="col-12 col-md">
                  <SearchFacet
                    attribute="speakers.speaker_name"
                    limit={5}
                    attributeTitle="Speaker"
                    acceptedValues={(speakers || defaultSpeakers).map((s) => ({ key: s, title: s }))}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="container pt-4">
          <Configure hitsPerPage={limit || 9} />
          <ConnectedSearchResults showHasMore={showHasMore} />
        </div>
      </AlgoliaSearch>
    )
  }
}

const ConnectedPorchMediaSearch = withTranslations(PorchMediaSearch)
export default ConnectedPorchMediaSearch
