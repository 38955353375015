/**
 * These breakpoints are meant to mirror the Bootstrap v4 breakpoints.
 *
 * @see https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
 */

export enum BreakpointSize {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}

export type Breakpoints = {
  [key in BreakpointSize]: number
}

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}
