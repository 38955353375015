import React, { FC } from 'react'

export interface IThumbnailProps {
  src: string
  alt: string
  viewMoreText?: string
}

export const Thumbnail: FC<IThumbnailProps> = ({ src, alt, viewMoreText }) => (
  <div className="thumbnail">
    <img src={src} alt={alt} />
    {viewMoreText && (
      <div className="thumbnail__overlay">
        <span className="thumbnail__text">{viewMoreText}</span>
        <svg className="thumbnail__circle" height="100" width="100">
          <circle cx="50" cy="50" r="40" />
        </svg>
      </div>
    )}
  </div>
)
