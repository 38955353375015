import wrapTopLevelComponent from '../javascripts/lib/wrap-top-level-component'
import ReactRailsUJS from '../javascripts/lib/react_ujs'
import path from 'path'

const componentRequireContext = import.meta.globEager('../javascripts/top-level-components/*.tsx')

ReactRailsUJS.useContext(componentRequireContext)

const wrappedGetConstructor = ReactRailsUJS.getConstructor

ReactRailsUJS.getConstructor = function(...args) {
  const lazyCtor = Promise.resolve(wrappedGetConstructor.apply(this, args))
  return lazyCtor.then((module) => wrapTopLevelComponent(module.default || module))
}

Object.keys(componentRequireContext).forEach((fullpath) => {
  const component = componentRequireContext[fullpath].default
  const componentName = path.basename(fullpath, path.extname(fullpath))

  window[componentName] = component
})
