import classNames from 'classnames'
import React, { FC } from 'react'
import { Carousel } from '../shared/porch'
import { breakpoints } from '../shared/porch'

export interface IMessageTopic {
  id: string
  title: string
  url: string
  color: string
}

export interface IPorchTopicsCarouselProps {
  topics: IMessageTopic[]
}

export const PorchTopicsCarousel: FC<IPorchTopicsCarouselProps> = ({ topics }) => {
  if (!topics.length) {
    return null
  }

  return (
    <Carousel
      className="porch-carousel--topics"
      sliderSettings={{
        slidesToShow: 4,
        responsive: [
          {
            breakpoint: breakpoints.md,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: breakpoints.sm,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      }}
    >
      {topics.map((topic) => (
        <a
          href={topic.url}
          key={topic.id}
          className={classNames('topic-card', {
            [`topic-card--${topic.color}`]: topic.color,
          })}
        >
          <h3 className="h5">{topic.title}</h3>
        </a>
      ))}
    </Carousel>
  )
}
