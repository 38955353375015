import * as React from 'react'
import {
  connectInfiniteHits,
  InfiniteHitsProvided,
} from 'react-instantsearch-core'
import CardBlock from './card-block'

interface IProps {
  columns: number
  showHasMore: boolean
}

const SearchResults: React.FunctionComponent<IProps & InfiniteHitsProvided> = ({
  hits,
  refineNext,
  hasMore,
  showHasMore,
  columns,
}) => (
  <>
    <div className="row justify-content-center">
      <div className="col col-content-container">
        <div className={`card-deck card-deck__${columns}-wide`}>
          <CardBlock hits={hits} />
        </div>
      </div>
    </div>

    {showHasMore && hasMore && (
      <div className="row justify-content-center">
        <div className="col col-content-container">
          <button
            className="d-block btn btn-info mx-auto mt-5"
            onClick={refineNext}
          >
            Load More Results
          </button>
        </div>
      </div>
    )}
  </>
)

const ConnectedSearchResults = connectInfiniteHits(SearchResults)

export default ConnectedSearchResults
