import { timeout, TimeoutError } from 'async-toolbox/timeout'
import WCC from 'wcc'

import { Stringable } from '../../util'
import { ILiveStream, IStreamEvent } from './models'

export const getLiveStream = async (id: Stringable | undefined, fetch: typeof window.fetch): Promise<ILiveStream | null> => {
  if (!id) { return null }

  const resp =
    await doFetch(`${WCC.CONSTANTS?.MEDIA_API_URL}/streams/${id}`
      || `https://media.watermark.org/api/v1/streams/${id}`, fetch)

  if (resp.status == 404) {
    return null
  }
  if (resp.status != 200) {
    throw new Error(`Unexpected status for ${resp.url} - ${resp.status}`)
  }
  const body: { stream: ILiveStream } = await resp.json()

  return body.stream
}

async function doFetch(url: string, fetch: typeof window.fetch): Promise<Response> {
  let resp: Response
  try {
    resp = await timeout(() => fetch(url), 10000)
  } catch (ex) {
    if (ex instanceof TimeoutError) {
      throw new ConnectionError(ex.message, 'timeout')
    }
    if (ex instanceof TypeError) {
      if (/^NetworkError/.test(ex.message)) {
        throw new ConnectionError(ex.message, 'connection-failed')
      }
    }
    throw ex
  }

  if (resp.status == 503) {
    throw new ConnectionError('503 Server Unavailable', 'server-unavailable')
  }
  return resp
}

export type {
  ILiveStream,
  IStreamEvent,
}

type ReasonCode = 'connection-failed' | 'server-unavailable' | 'timeout'

/** Thrown when the browser's connection to Paper Signs fails */
export class ConnectionError extends Error {
  constructor(error: any, public readonly reason: ReasonCode) {
    super(error)
  }
}
