import { ensure, IProps } from './index'
import { IStruggle } from './results'

function mapScores(params: URLSearchParams, struggles: IStruggle[], minScoreThreshold: number) {

  const map: Map<string, number> = new Map<string, number>()
  const severeMap: Map<string, number> = new Map<string, number>()

  struggles.forEach((value) => {
    if (!params.has(value.form_key + '_score') && !params.has(value.form_key + '_total')) {
      return
    } else if (params.has(value.form_key + '_score') && params.has(value.form_key + '_total')) {
      const score: number = Number(params.get(value.form_key + '_score'))
      const total: number = Number(params.get(value.form_key + '_total'))

      if (score == 0) { return } 

      if (value.severe && score >= minScoreThreshold) {
        severeMap.set(value.form_key, Math.floor((score / total) * 100))

        return
      }

      map.set(value.form_key, Math.floor((score / total) * 100))
    }
  })

  const sortedSevereMap = new Map(Array.from(severeMap.entries()).sort((a, b) => b[1] - a[1]))
  const sortedMap = new Map(Array.from(map.entries()).sort((a, b) => b[1] - a[1]))

  return new Map([...sortedSevereMap, ...sortedMap])
}

export interface IGetTopResultsProps extends IProps {
  params: URLSearchParams
}

export function checkPersistedResults() {
  const results = window.localStorage.getItem('results')

  if (results) {
    const retrievedResults = JSON.parse(results)

    if (retrievedResults.expiry < Date.now() ) {
      window.localStorage.removeItem('results')
      return null
    }

    return retrievedResults.results
  }

  return null
}

export function getTopResults({
  resultInfo,
  maxPercentThreshold,
  minPercentDifference,
  minScoreThreshold,
  maxPercentDifference,
  maxCards,
  params,
  struggles,
}: IGetTopResultsProps ) {

  if (params.toString().length == 0 ) {
    return checkPersistedResults()
  }

  const sortedStruggles: IStruggle[] = struggles.sort((a, b) => Number(b.severe) - Number(a.severe))
  const lowScoreThreshold = minScoreThreshold || 3
  const results: IStruggle[] | undefined = []
  let lowScores = true
  let highPercents = true

  const scores = mapScores(params, sortedStruggles, lowScoreThreshold)

  sortedStruggles.forEach((value) => {
    if (params.has(value.form_key + '_score')) {
      const score: number = Number(params.get(value.form_key + '_score'))
      if (score > lowScoreThreshold) {
        lowScores = false
      }
    }
  })

  scores.forEach((value) => {
    if (value < (maxPercentThreshold || 90)) {
      highPercents = false
    }
  })

  if (lowScores) {
    resultInfo.lowScoreStruggles.forEach((value) => {
      results.push(ensure(sortedStruggles.find((struggle) => struggle.form_key === value)))
    })
  } else if (highPercents) {
    resultInfo.highPercentStruggles.forEach((value) => {
      results.push(ensure(sortedStruggles.find((struggle) => struggle.form_key === value)))
    })
  } else {
    const topScoresKeys = Array.from(scores.keys()).slice(0, maxCards || 3)
    const topScoresValues = Array.from(scores.values()).slice(0, maxCards || 3)

    if ((topScoresValues[0] - topScoresValues[1]) > (maxPercentDifference || 50)) {
      results?.push(ensure(sortedStruggles.find((struggle) => struggle.form_key === topScoresKeys[0])))
    } else if ((topScoresValues[1] - topScoresValues[2]) > (minPercentDifference || 30)) {
      results?.push(ensure(sortedStruggles.find((struggle) => struggle.form_key === topScoresKeys[0])))
      results?.push(ensure(sortedStruggles.find((struggle) => struggle.form_key === topScoresKeys[1])))
    } else {
      topScoresKeys.forEach((value) => {
        results?.push(ensure(sortedStruggles.find((struggle) => struggle.form_key === value)))
      })
    }
  }

  const struggleResults = {
    results,
    expiry: Date.now() + 604800,
  }
  window.localStorage.setItem('results', JSON.stringify(struggleResults))

  return results
}
