import * as React from 'react'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { getTopResults } from './get-top-results'
import Results, { IStruggle } from './results'

export interface IProps extends ITranslationsProvided {
  resultInfo: IResultInfo,
  launchPageUrl: string,
  struggles: IStruggle[]
  maxPercentThreshold?: number,
  minScoreThreshold?: number,
  maxPercentDifference?: number,
  minPercentDifference?: number,
  maxCards?: number,
  params?: URLSearchParams
}

/**
 * The struggles to list when the all scores are below or above the threshhold
 */
export interface IResultInfo {
  /** The struggles to list whenever all scores in the params are below a score of 3 */
  lowScoreStruggles: string[],
  /** The struggles to list whenever all total percentages,
   * which is the score divided by the max points, are all above 90%
   */
  highPercentStruggles: string[]
}

class RegenStrugglesResults extends React.Component<IProps> {
  public render() {
    const { launchPageUrl } = this.props

    const valid = this.checkValidParams()
    if (!valid) {
      window.location.href = launchPageUrl
    }

    const params = this.props.params || new URLSearchParams(window.location.search)
    const results = getTopResults({ ...this.props, params})

    if (valid) {
      window.history.replaceState(null, '', window.location.pathname)
      return <Results results={results} />
    } else {
      return <div></div>
    }
  }

  private checkValidParams = () => {
    const { struggles } = this.props
    const params = this.props.params || new URLSearchParams(window.location.search)
    let valid: boolean = true

    if (params.toString().length == 0) {
      if (window.localStorage.getItem('results')) {
        return true
      }
      return false
    }

    struggles.forEach((value) => {
      if (!params.has(value.form_key + '_score') && !params.has(value.form_key + '_total')) {
        return
      } else if (!params.has(value.form_key + '_score')) {
        valid = false
      } else if (params.get(value.form_key + '_score')?.length == 0
        || params.get(value.form_key + '_total')?.length == 0) {
        valid = false
      }
    })

    return valid
  }
}

// Ensures that the argument exists. This is to prevent the find() method from returning an IStruggle | undefined
export function ensure(argument: IStruggle | undefined) {
  if (argument === undefined) {
    throw new TypeError('This value was promised to be there.')
  }

  return argument
}

export default withTranslations(RegenStrugglesResults)
