import {
  GalleryBlockHit,
  IMediaApiMessage,
  MediaGallery,
  MediaGallerySource,
} from '@watermarkchurch/react-instantsearch-components'
import * as React from 'react'

import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { ICompositeMessage } from '../../models/algolia/composite'

interface IProps {
  campusAware: boolean
  mediaSourceDefaults: Partial<MediaGallerySource>
  mediaSources: {
    campusAgnostic: MediaGallerySource,

    [campus: string]: MediaGallerySource,
  }
  header?: string | false
  ministry?: string
  campus?: { code: string, name: string }
  viewAllLink?: string
}

class MediaGalleryWrapper extends React.Component<IProps & ITranslationsProvided> {

  public render() {
    const {t, header, ministry, campus} = this.props

    // In Paper Signs, distinct from other apps, we have campus aware media sources
    const mediaSource: MediaGallerySource = {
      ...this.detectMediaSourceForCampus(),
    }

    if (mediaSource.source == 'Algolia') {
      // For the Paper Signs composite index, we need to restrict to Message objects
      mediaSource.hiddenFacets = {
        object_type: 'Message',
        ...mediaSource.hiddenFacets,
      }
    }

    const defaultHeader =
      ministry ?
        campus ?
          t('header.with_ministry_and_campus', { ministry, campus: campus && campus.name })
          : t('header.with_ministry', { ministry })
        : t('header.default')

    return <div className="row justify-content-center">
      <div className="col-content-container">
        <div className="row">
          <div className="col-12">
            <MediaGallery {...this.props}
              mediaSource={mediaSource} header={header || defaultHeader}
              HitComponent={MediaGalleryHitWrapper}
            />
          </div>
        </div>
      </div>
    </div>

  }

  private detectMediaSourceForCampus() {
    const { campusAware, campus, mediaSources } = this.props

    const campusSpecificSettings = (campusAware && campus) ? mediaSources[campus.code] : undefined

    return campusSpecificSettings || mediaSources.campusAgnostic
  }
}

const ConnectedMediaGalleryWrapper = withTranslations(MediaGalleryWrapper)
export default ConnectedMediaGalleryWrapper

interface IHitWrapperProps {
  hit: ICompositeMessage | IMediaApiMessage
}

const MediaGalleryHitWrapper = ({hit}: IHitWrapperProps) => {
  const message =
    'objectID' in hit ?
      // When we render a hit from the composite index, we need the data from the hit['Message'] property
      {
        ...hit,
        ...hit.Message,
      } :
      hit

  return <GalleryBlockHit hit={message}
    href={`/message/${message.id}-${message.slug}`} />
}
