import * as React from 'react'
import { Hit } from 'react-instantsearch-core'

import { ICompositePost } from '../../models/algolia/composite'

interface IProps {
  hit: Hit<ICompositePost>
}

const StoryCard: React.FunctionComponent<IProps> = ({ hit }) => {
  const { slug, title, thumbnail_image_url, hero_image_url, tags } = hit.Post

  return (
    <div className="card skin-dark story-card regen-story-card-square">
      <div
        className="card-image"
        style={{
          backgroundImage: `url('${thumbnail_image_url || hero_image_url}')`,
        }}
      >
        <div className="card-body">
          <p className="overline">
            {tags.map((tag) => (
              <a key={tag.key} className="tag" href={`/search?query=${encodeURIComponent(tag.title)}`}>
                {tag.title}
              </a>
            ))}
          </p>

          <p className="card-title">{title}</p>

          <div className="card-cta">
            <a href={slug} className="btn btn-primary">
              <i className="material-icons">play_arrow</i>
              Watch
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

interface IWrapperProps extends IProps {
  wrapperClassName?: string
}

const WrappedStoryCard: React.FunctionComponent<IWrapperProps> = ({
  wrapperClassName = 'col-12 bottom-gutter col-md-4',
  ...props
}) => (
  <div className={wrapperClassName}>
    <StoryCard {...props} />
  </div>
)
const generateWrappedStoryCard = (wrapperClassName: string) => (
  // eslint-disable-next-line react/display-name
  (props: IProps) => <WrappedStoryCard wrapperClassName={wrapperClassName} {...props} />
)
export { WrappedStoryCard, generateWrappedStoryCard }

export default StoryCard
