import * as React from 'react'
import { HitsProvided } from 'react-instantsearch-core'
import { connectHits } from 'react-instantsearch-dom'
import { IPorchMessageHit } from '../../models/algolia/messages_production'
import { PorchCardsCarouselItems } from '../porch-cards-carousel-items'

export type IPorchLatestMessageCarouselResultsProps = HitsProvided<IPorchMessageHit>

export const PorchLatestMessageCarouselResults: React.FC<IPorchLatestMessageCarouselResultsProps> = ({
  hits
}) => {
  return <div>
    <PorchCardsCarouselItems
      slidesToShow={1}
      slidesToShowMd={1}
      slidesToShowSm={1}
      imagePosition="bottom"
      navPosition="top"
      items={hits} />
  </div>
}

export const ConnectedPorchLatestMessageCarouselResults = connectHits(PorchLatestMessageCarouselResults)
