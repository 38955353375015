import * as React from 'react'
import { Hit } from 'react-instantsearch-core'

import { ICompositePost } from '../../models/algolia/composite'
import { WrappedStoryCard } from './story-card'

interface IProps {
  hits: Array<Hit<ICompositePost>>
}

const CardBlock: React.FunctionComponent<IProps> = ({ hits }) => {

  return (
    <div className="row half-gutters">
      {hits.map((hit) => <WrappedStoryCard key={hit.objectID} hit={hit} />)}
    </div>
  )

}

export default CardBlock
