import * as React from 'react'
import { Hit } from 'react-instantsearch-core'

import { IRegenCompositePost } from '../../models/algolia/composite'
import ResourceCard from '../regen-resources-list/resource-card'
import { generateWrappedStoryCard } from '../regen-stories-list/story-card'

interface IProps {
  hits: Array<Hit<IRegenCompositePost>>
}

const StoryCard = generateWrappedStoryCard('card-wrapper')

const CardBlock: React.FunctionComponent<IProps> = ({ hits }) => {
  return (
    <>
      {hits.map((hit) => {
        const Card = hit.regen_result_type === 'Resources' ? ResourceCard : StoryCard
        return <Card key={hit.objectID} hit={hit} />
      })}
    </>
  )
}

export default CardBlock
