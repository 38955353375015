import I18n from 'i18n-js'
import * as React from 'react'
import { ConnectedComponentClass } from 'react-instantsearch-core'

I18n.missingTranslation = () => null

declare module 'i18n-js' {
  export function interpolate(message: string, options: TranslateOptions): string

  export function pluralize(count: number, scope: Scope, options: TranslateOptions): string
}

export type Translate = (key: string | string[],  options?: I18n.TranslateOptions) => string
export type LoadTranslations = (key: string | string[]) => ITranslationObject

export interface ITranslationsProvided {
  t: Translate,
  translationsAt: LoadTranslations
}

export interface ITranslationsExposed {
  /** Specifies a prefix to be prepended to all t() function calls by this component */
  translationPrefix?: string | string[]
}

const TranslationContext = React.createContext<ITranslationsProvided>({
  t: (key, params) => I18n.t(key, params),
  // as long as count is not set, i18n.translate(key) just returns the object.
  translationsAt: (key) => I18n.translate(key) as any,
})

export interface ITranslationObject {
  [key: string]: string | ITranslationObject
}

interface IProps extends Partial<ITranslationsProvided> {
  translations: ITranslationObject
}

export class ProvideTranslations extends React.PureComponent<IProps> {

  public render() {
    const { children } = this.props

    return <TranslationContext.Consumer>
      {({ t: parent, translationsAt }) =>
        <TranslationContext.Provider value={{
          t: this.makeTranslateFunc(parent),
          translationsAt: this.makeLoadFunc(translationsAt),
        }}>
          {children}
        </TranslationContext.Provider>}
    </TranslationContext.Consumer>
  }

  private makeTranslateFunc = (parent: Translate): Translate => {
    const {translations} = this.props

    return (key, options) => {
      let translation = pickTranslation(translations, key)
      if (!translation) {
        return parent(key, options)
      }

      options = Object.assign({}, options, { defaultValue: translation })
      if (typeof(translation) === 'string') {
        translation = I18n.interpolate(translation, options)
      } else if (isObject(translation) && isSet(options.count)) {
        translation = I18n.pluralize(options.count, 'xxxxx', options)
      }

      return translation as string
    }
  }

  private makeLoadFunc = (parent: (key: string | string[]) => ITranslationObject): LoadTranslations => {
    const {translations} = this.props

    return (key: string | string[]) => {
      const result = pickTranslation(translations, key)
      if (!result || typeof result == 'string') {
        return parent(key)
      }

      return result
    }
  }
}

export class PrefixTranslations extends React.PureComponent<{ prefix: string | string[] }> {

  public render() {
    const { children } = this.props
    const {prefix} = this.props

    /*
     * given:
     *   section:
     *     key: 'value',
     *     other_key: 'other value'
     *     sub_section:
     *       key: 'more specific value'
     * and prefix == 'section.sub_section'
     *
     * t('key') => 'more specific value'
     * t('other_key') => 'other value'
     * translationsAt('.')['key'] => 'more specific value'
     * translationsAt('.')['other_key'] => 'other value'
     */

    return <TranslationContext.Consumer>
      {({ t: parent, translationsAt }) =>
        <TranslationContext.Provider value={{
          t: (key, params) => {
            const prefixed = parent(prefixKey(key, prefix), params)
            if (isNil(prefixed)) {
              return parent(key, params)
            }
            return prefixed
          },
          translationsAt: (key) => ({
            ...translationsAt(key),
            // override with the prefixed translations (more specific)
            ...translationsAt(prefixKey(key, prefix)),
          }),
        }}>
          {children}
        </TranslationContext.Provider>}
    </TranslationContext.Consumer>
  }
}

export function withTranslations<Props extends Partial<ITranslationsProvided>>(
  WrappedComponent: React.ComponentType<Props>,
  defaultTranslations?: ITranslationObject,
): ConnectedComponentClass<Props, ITranslationsProvided, ITranslationsExposed>
export function withTranslations<Props extends Partial<ITranslationsProvided>>(
  WrappedComponent: React.ComponentType<Props>,
  /** The default prefix for all translations - overridden by setting the translationPrefix prop */
  prefix?: string,
  defaultTranslations?: ITranslationObject,
): ConnectedComponentClass<Props, ITranslationsProvided, ITranslationsExposed>

export function withTranslations<Props extends ITranslationsProvided>(
  WrappedComponent: React.ComponentType<Props>,
  prefixOrDefaultTranslations?: string | ITranslationObject,
  defaultTranslations?: ITranslationObject,
): ConnectedComponentClass<Props, ITranslationsProvided, ITranslationsExposed> {
  let prefix: string | undefined
  if (typeof prefixOrDefaultTranslations != 'string') {
    defaultTranslations = prefixOrDefaultTranslations
  } else {
    prefix = prefixOrDefaultTranslations
  }

  return class extends React.Component<Props & ITranslationsExposed> {
    public displayName = `withTranslations${WrappedComponent.displayName}`

    public render() {
      return <TranslationContext.Consumer>
        {({ t: translate, translationsAt }) =>
          <WrappedComponent {...this.props}
            t={this.translateFn(translate)}
            translationsAt={this.translateFn(translationsAt)} />}
      </TranslationContext.Consumer>
    }

    private translateFn = (translate: Translate | LoadTranslations) => {
      const {translationPrefix} = this.props

      return (key: string | string[], params: any) => {
        key = prefixKey(key, translationPrefix || prefix)

        const tx = translate(key, params)
        // since empty string is falsy, use isNil here
        if (isNil(tx)) {
          return pickTranslation(defaultTranslations, key)
        }
        return tx
      }
    }
  } as unknown as ConnectedComponentClass<Props, ITranslationsProvided, ITranslationsExposed>
}

export function pickTranslation(
  translations: ITranslationObject | undefined, key: string | string[],
): string | ITranslationObject | null {
  if  (!translations || key.length == 0) {
    return null
  }

  if (typeof key == 'string') {
    key = key.split('.')
  }

  const k = key[0]
  const tx = translations[k]
  if (typeof tx == 'string' || key.length == 1) {
    return tx
  }

  return pickTranslation(tx, key.slice(1))
}

function prefixKey(key: string | string[], prefix?: string | string[]): string[] {
  if (typeof key == 'string') {
    key = key.split('.')
  }
  if (!prefix || prefix.length == 0) {
    return key
  }

  if (typeof prefix == 'string') {
    prefix = prefix.split('.')
  }
  return prefix.concat(...key)
}

// Is a given variable an object?
// Borrowed from Underscore.js
const isObject = (obj: any) => {
  const type = typeof obj
  return type === 'function' || type === 'object'
}

// Check if value is different than undefined and null;
const isSet = (value: any) => {
  return typeof(value) !== 'undefined' && value !== null
}

/** https://github.com/lodash/lodash/blob/master/isNil.js#L19 */
function isNil<T>(value: T | null | undefined): value is null | undefined {
  return value == null
}
