import * as React from 'react'

import { CountdownContainer, now, pluralize, round } from './util'

interface IPart {
  label: string
  value: number
}

interface IProps {
  labelText: string
  target: Date
  onExpiry?: () => void
  onRefresh?: () => void
}

interface IState {
  from: number
  target: number
}

export class Timer extends React.Component<IProps, IState> {
  private interval?: number

  constructor(props: IProps) {
    super(props)
    const targetTime = round(this.props.target).getTime()

    this.state = {
      from: now().getTime(),
      target: targetTime,
    }
  }

  public componentDidMount() {
    if (this.props.onRefresh) { this.props.onRefresh() }

    this.interval = setInterval(this.tick, 1000)
  }

  public componentWillUnmount() {
    if (!this.interval) { return }

    clearInterval(this.interval)
  }

  public render() {
    return <CountdownContainer>
      <div className="countdown-timer__clock">
        {this.parts().map((part) =>
          <span className="countdown-timer__clock__part" key={`part-${part.label}`}>
            <span className="countdown-timer__clock__part__number">
              {part.value >= 10 ? part.value : `${part.value}`}
            </span>
            <h4 className="countdown-timer__clock__part__label">{part.label}</h4>
          </span>,
        )}
      </div>
      <h2 className="countdown-timer__label-text">{this.props.labelText}</h2>
      {this.props.children}
    </CountdownContainer>
  }

  private diffInSeconds() {
    return (this.state.target - this.state.from) / 1000
  }

  private tick = () => {
    if (this.diffInSeconds() <= 0) {
      if (this.props.onExpiry) { this.props.onExpiry() }
      return
    }

    this.setState({ from: now().getTime() })
  }

  private parts(): IPart[] {
    const parts: IPart[] = []
    const diffInSeconds = this.diffInSeconds()

    const seconds = diffInSeconds % 60
    const minutes = Math.floor(diffInSeconds / 60) % 60
    const hours = Math.floor(diffInSeconds / 60 / 60) % 24
    const days = Math.floor(diffInSeconds / 60 / 60 / 24)

    if (days > 0) {
      parts.push({ label: pluralize('Day', days), value: days })
    }

    return parts.concat([
      { label: pluralize('Hour', hours), value: hours },
      { label: pluralize('Minute', minutes), value: minutes },
      { label: pluralize('Second', seconds), value: seconds },
    ])
  }
}
