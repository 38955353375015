import WCC from 'wcc'
import searchInsights from 'search-insights'

if (typeof window != 'undefined' &&
    WCC.CONSTANTS &&
    WCC.CONSTANTS.ALGOLIA_APP_ID &&
    WCC.CONSTANTS.ALGOLIA_SEARCH_API_KEY
) {

  searchInsights('init', {
    appId: WCC.CONSTANTS?.ALGOLIA_APP_ID,
    apiKey: WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY ,
  })
}

export type InsightsClient = (
  method: InsightsClientMethod,
  payload: IInsightsClientPayload,
) => void

export type InsightsClientMethod =
  | 'clickedObjectIDsAfterSearch'
  | 'convertedObjectIDsAfterSearch'

export interface IInsightsClientPayload {
  index: string
  queryID: string
  eventName: string
  objectIDs: string[]
  positions?: number[]
}

declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    aa: InsightsClient
  }
}

export default searchInsights

export interface IInsightsDataAttributes {
  objectId: string
  position?: number
  queryId?: string
  objectType?: string
}

export function getInsightsData(elem: JQuery<any>): IInsightsDataAttributes | undefined {
  const data = elem.data()
  if (!data.objectId) {
    return undefined
  }

  return data as IInsightsDataAttributes
}

export function buildAnalyticsAttributes(hit: { objectID: string }) {
  const hitInternal = hit as unknown as {
    objectID: string, __position: number, __queryID: string,
    object_type?: string,
  }
  const hitAttributes = {
    'data-object-id': hitInternal.objectID,
    'data-position': hitInternal.__position,
    'data-query-id': hitInternal.__queryID,
    'data-object-type': hitInternal.object_type,
  }
  return hitAttributes
}
