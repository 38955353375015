/**
 * A helper function to get a random index from an array, excluding the given array of indexes.
 */
export const getRandomIndex = (arr: any[], exclude?: number[]): number => {
  const randomIndex = Math.floor(Math.random() * arr.length)
  const shouldExclude = exclude ? exclude.indexOf(randomIndex) : -1

  if (shouldExclude > -1) {
    return getRandomIndex(arr, exclude)
  } else {
    return randomIndex
  }
}
