import * as React from 'react'
import { HitsProvided } from 'react-instantsearch-core'
import { connectHits } from 'react-instantsearch-dom'
import locationDallasUrl from '../../../../images/porch/location-dallas.jpg'
import locationGenericUrl from '../../../../images/porch/location-generic.jpg'

export interface IPorchLocationHit {
  'address'?: {
    'street1': string,
    'city': string,
    'country': string,
    'state': string,
    'zip': string,
    'street2': string,
  },
  'contact': {
    'name': string,
    'email': string,
    'phone': string,
  },
  'locationDisplayName'?: string | null | undefined,
  'description': string,
  'hasChildcare': boolean,
  'launchDate': string,
  'meetingDay': string,
  'meetingTime': string,
  'ministry': string,
  'thumbnailUrl': string,
  'title': string,
  'trainingLocation': boolean,
  'website': string,
  'places': null,
  '_geoloc': {
    'lat': number,
    'lng': number,
  },
  'objectID': string,
}

export type IPorchLocationListResultsProps = HitsProvided<IPorchLocationHit>

export const PorchLocationListResults: React.FC<IPorchLocationListResultsProps> = ({ hits }) => {
  React.useEffect(() => {
    const modalLocation = window.location.hash?.replace(/#/g, '').replace(/%20/g, '-').toLowerCase()

    $('#location_' + modalLocation).modal('show')
  })

  return <div className="card-grid row">
    {hits.map((hit) => {
      const cardTitle = hit.locationDisplayName || hit.address?.city

      return <div key={hit.objectID} className="col-12 col-sm-6 col-md-4">
        <div className="card card--event">
          <div className="card-body">
            <h4 className="card-title">{cardTitle}</h4>
            <h6>
              {hit.meetingDay &&
                hit.meetingDay
              }
              {hit.meetingTime &&
                ' @ ' + hit.meetingTime
              }
            </h6>
            <p>
              {hit.title}<br />
              {hit.address?.street1}<br />
              {hit.address?.city}, {hit.address?.state} {hit.address?.zip}
            </p>
            <a href="#"
              className="card-action"
              data-toggle="modal"
              data-target={'#location_' + hit.address?.city.replace(/ /g, '-').toLowerCase()}
            >
              Learn More
            </a>
          </div>
          <div className="card-footer">
          </div>
        </div>
        <div className="porch-location-list__modal modal fade"
          id={'location_' + hit.address?.city.replace(/ /g, '-').toLowerCase()}
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-body">
                <div className="location-modal__content">
                  <button type="button" className="close overline" data-dismiss="modal" aria-label="Close">
                    <i className="material-icons mr-2">west</i>
                    Back To All Locations
                  </button>
                  <h3 className="mt-4">{cardTitle}</h3>
                  <p>{hit.description}</p>
                  <table className="location-modal-details">
                    <tbody>
                      {hit.meetingDay || hit.meetingTime ? (
                        <>
                          <tr>
                            <td className="location-modal-details-col">
                              <i className="material-icons">event</i>
                            </td>
                            <td className="location-modal-details-col">
                              {hit.meetingDay && (
                                hit.meetingDay
                              )}
                              {hit.meetingTime && (
                                ' @ ' + hit.meetingTime
                              )}
                            </td>
                          </tr>
                        </>
                      ) : null}
                      <tr>
                        <td className="location-modal-details-col">
                          <i className="material-icons">place</i>
                        </td>
                        <td className="location-modal-details-col">
                          {hit.title}<br />
                          {hit.address?.street1}<br />
                          {hit.address?.city}, {hit.address?.state} {hit.address?.zip}<br />
                          <a className="location-modal-link mt-3"
                            href={
                              'https://www.google.com/maps/search/?api=1&query='
                              + hit.address?.street1?.replace(/ /g, '%20')
                              + '%2C' + hit.address?.city?.replace(/ /g, '%20') + '%2C'
                              + hit.address?.state?.replace(/ /g, '%20') + '%2C'
                              + hit.address?.zip?.replace(/ /g, '%20')
                            }
                            target="_blank" rel="noreferrer"
                          >
                            Get Directions
                            <i className="material-icons">north_east</i>
                          </a>
                        </td>
                      </tr>
                      {hit.contact?.email && (
                        <>
                          <tr>
                            <td className="location-modal-details-col">
                              <i className="material-icons">alternate_email</i>
                            </td>
                            <td className="location-modal-details-col">
                              <a className="location-modal-link" href={'mailto:' + hit.contact?.email} target="_blank" rel="noreferrer">
                                {hit.contact?.email}
                              </a>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div className="location-modal__image">
                  {hit.address?.city == 'Dallas' ? (
                    <img alt='' src={locationDallasUrl} />
                  ) : (
                    <img alt='' src={locationGenericUrl} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    })}
  </div>

}

export const ConnectedPorchLocationListResults = connectHits(PorchLocationListResults)
