import { format } from 'date-fns'
import * as React from 'react'
import { connectInfiniteHits, InfiniteHitsProvided } from 'react-instantsearch-core'
import { IPorchMessageHit, messageHref } from '../../models/algolia/messages_production'

interface IProps {
  basePath: string
  showHasMore: boolean
}

const MediaPostHit: React.FC<{ hit: IPorchMessageHit, basePath: string }> = ({hit, basePath}) => {
  const thumb = hit.thumbnail_url?.replace('http://', 'https://')

  return <div className="col-12">
    <div className="card card--split">
      <a href={messageHref(hit, basePath)} className="card-img-top stretched-link">
        <div className="thumbnail">
          <img alt={hit.title} className="thumbnail__image" src={thumb} />
          <div className="thumbnail__overlay">
            <span className="thumbnail__text">Listen</span>
            <svg className="thumbnail__circle" height="100" width="100">
              <circle cx="50" cy="50" r="40"></circle>
            </svg>
          </div>
        </div>
      </a>
      <div className="card-body">
        <p className="card-meta overline mb-3">
          {hit.series_position && (
            <>
              Episode {hit.series_position}
            </>
          )}
          {hit.series_position && hit.date && (
            <>
              &nbsp;&bull;&nbsp;
            </>
          )}
          {hit.date ?
            format(new Date(hit.date), 'MMM d, yyyy')
            : ''}
        </p>
        <h3 className="h5 card-title">{hit.title}</h3>
        <p className="card-text">{hit.description}</p>
        <div className="card-actions mt-4">
          <button className="btn btn-primary play-btn p-3">
            <i className="material-icons">play_arrow</i>
          </button>
          <span className="ml-3">
            {hit.duration && (
              Math.floor(hit.duration / 60) + ':' + ('0' + Math.floor(hit.duration % 60)).slice(-2)
            )}
          </span>
        </div>
      </div>
    </div>
  </div>
}

export const SearchResults: React.FunctionComponent<IProps & InfiniteHitsProvided<IPorchMessageHit>> = ({
  showHasMore,
  refineNext,
  hasMore,
  hits,
  basePath,
}) => (
  <>
    <div className="card-grid row">
      {hits.map((hit) =>
        <MediaPostHit key={hit.objectID} hit={hit} basePath={basePath} />)}
    </div>

    {showHasMore && hasMore && (
      <div className="row justify-content-center">
        <div className="col col-content-container">
          <button
            className="d-block btn btn-primary mx-auto mt-5"
            onClick={refineNext}
          >
            Load More Messages
          </button>
        </div>
      </div>
    )}
  </>
)

const ConnectedSearchResults = connectInfiniteHits(SearchResults)

export default ConnectedSearchResults
