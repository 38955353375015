import { GoogleApiWrapper } from '@watermarkchurch/google-maps-react'
import WCC from 'wcc'
import { assert } from '../util'

assert(WCC.CONSTANTS?.GOOGLE_MAPS_API_KEY)
const connectGoogleMaps = GoogleApiWrapper({
  apiKey: WCC.CONSTANTS?.GOOGLE_MAPS_API_KEY,
  libraries: ['places', 'geometry'],
})

export default connectGoogleMaps
