import * as React from 'react'
import { Hit } from 'react-instantsearch-core'

import { ICompositePost } from '../../models/algolia/composite'
import SearchHit from './search-hit'

interface IProps {
  hits: Array<Hit<ICompositePost>>
}

const ResultBlock: React.FunctionComponent<IProps> = ({ hits }) => {

  return (
    <>
      {hits.map((hit) => <SearchHit key={hit.objectID} hit={hit} />)}
    </>
  )

}

export default ResultBlock
