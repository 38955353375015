import * as React from 'react'
import {
  connectHits,
  connectStateResults,
  StateResultsProvided,
} from 'react-instantsearch-core'
import ResultBlock from '../regen-site-search/result-block'

const ConnectedResultBlock = connectHits(ResultBlock)

interface IProps {
  ResultBlock?: typeof ConnectedResultBlock
}

const getToggleSections = (): HTMLElement[] => {
  if (typeof document === 'undefined') {
    return []
  }

  return Array.from(document.querySelectorAll('.below-blog-search-sections'))
}

const SearchResults: React.FunctionComponent<IProps & StateResultsProvided> = ({
  ResultBlock: Results,
  searchState,
}) => {
  const showResults = !!searchState.query || !!searchState?.refinementList?.['Post.tags.title']?.length
  Results = Results || ConnectedResultBlock

  React.useEffect(() => {
    getToggleSections()
      .forEach((section) => section.style.display = showResults ? 'none' : 'block')
  }, [showResults])

  if (!showResults) {
    return null
  }

  return (
    <div className="col-content-container px-0 mx-auto">
      <div className="row">
        <div className="col-12">
          <Results />
        </div>
      </div>
    </div>
  )
}

const ConnectedSearchResults = connectStateResults(SearchResults)

export default ConnectedSearchResults
