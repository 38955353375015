import * as React from 'react'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'

interface IProps {
  results?: IStruggle[]
}
interface IStruggle {
  title: string
  description: string
  url: string
  style: string
  form_key: string
  tags: string[],
  severe: boolean
}

class Results extends React.Component<IProps & ITranslationsProvided> {
  public render() {
    const { results } = this.props
    return (
      <div className="col-content-container mx-auto p-0 regen-struggles-list">
        <div className="row">
          {results && results.map((struggle, index) => (
            <div className="col col-12 col-md-4 regen-struggles-list__result" key={index}>
              <div className={`card ${struggle.style}`}>
                <div className="card-body">
                  <h4 className="card-title">{struggle.title}</h4>
                  <div className="card-text">
                    <div className="formatted-content">
                      <p>{struggle.description}</p>
                    </div>
                  </div>
                  <a href={struggle.url} className="btn btn-info">Learn More</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
}

export {
  IStruggle,
}

export default withTranslations(Results)
