import * as React from 'react'

const NavButtons = ({ onSearch }: { onSearch: () => void }) => {
  const callback = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      onSearch()
    },
    [onSearch],
  )

  return (
    <ul className="navbar-nav search-menu">
      <li className="nav-item">
        <a className="nav-link" tabIndex={0} href="/locations">
          <i className="material-icons-outlined">room</i>
          <span>Find a Group</span>
        </a>
      </li>
      <li className="nav-item">
        <a
          href="/search"
          onClick={callback}
          className="nav-link icon-only"
          tabIndex={0}
        >
          <i className="material-icons">search</i>
        </a>
      </li>
    </ul>
  )
}

const SearchForm = ({
  initialQuery,
  onCancel,
}: {
  initialQuery: string;
  onCancel: () => void;
}) => {
  const [query, setQuery] = React.useState<string>(initialQuery)
  const callback = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault()
      onCancel()
    },
    [onCancel],
  )

  return (
    <form method="get" action="/search" className="search-nav-form">
      <button
        type="submit"
        title="Submit your search query"
        className="search-nav-submit"
      >
        <i className="material-icons">search</i>
      </button>
      <button
        onClick={callback}
        type="reset"
        title="Close the search form"
        className="search-nav-reset"
      >
        <i className="material-icons">clear</i>
      </button>
      <input
        type="search"
        placeholder="Search"
        name="query"
        value={query}
        onChange={(e) => setQuery(e.currentTarget.value)}
        className="search-nav-input"
        autoFocus
      />
    </form>
  )
}

const RegenNavSearch = ({ initialQuery }: { initialQuery?: string }) => {
  const [showSearch, setShowSearch] = React.useState<boolean>(false)

  return (
    <div className="search-nav">
      {showSearch ? (
        <SearchForm
          initialQuery={initialQuery || ''}
          onCancel={() => setShowSearch(false)}
        />
      ) : (
        <NavButtons onSearch={() => setShowSearch(true)} />
      )}
    </div>
  )
}

export default RegenNavSearch
