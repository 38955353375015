import classNames from 'classnames'
import { merge } from 'lodash'
import React, { FC, ReactElement, useRef, useState } from 'react'
import Slider, { Settings as SlickSettings } from 'react-slick'
import { CarouselContext } from './carousel-context'

export interface ICarouselProps {
  className?: string
  sliderSettings?: SlickSettings
  children: ReactElement[]
}

export const Carousel: FC<ICarouselProps> = ({ sliderSettings, className, children }) => {
  const [currentSlide, setCurrentSlide] = useState(sliderSettings?.initialSlide || 0)
  const sliderRef = useRef<Slider>(null)
  sliderSettings = merge(
    {},
    {
      dots: false,
      infinite: false,
      autoplay: false,
      slidesToShow: 3,
      slidesToScroll: 1,
    },
    sliderSettings,
  )

  const play = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay()
    }
  }

  const pause = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPause()
    }
  }

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext()
    }
  }

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev()
    }
  }

  const goToSlide = (slideIndex: number) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(slideIndex)
    }
  }

  const handleAfterChange = (newCurrentSlide: number) => {
    setCurrentSlide(newCurrentSlide)

    if (sliderSettings?.afterChange) {
      sliderSettings.afterChange(newCurrentSlide)
    }
  }

  if (!children) {
    return null
  }

  return (
    <CarouselContext.Provider
      value={{
        currentSlide,
        goToNextSlide,
        goToPrevSlide,
        pause,
        play,
        goToSlide,
      }}
    >
      <Slider
        {...sliderSettings}
        afterChange={handleAfterChange}
        ref={sliderRef}
        className={classNames('porch-carousel', className)}
      >
        {React.Children.map(children, (child) => (
          <div className="porch-carousel__item">{child}</div>
        ))}
      </Slider>
    </CarouselContext.Provider>
  )
}
