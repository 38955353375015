import * as React from 'react'
import MobileThumbnailList from './mobile-thumbnail-list'
import ThumbnailList from './thumbnail-list'
import VideoPlayer from './video-player'

export interface IVideo {
  iframeSrc: string
  thumbnailImageSrc: string
}

interface IProps {
  videos: IVideo[]
  windowSize?: number
}

const RegenVideoGallery: React.FunctionComponent<IProps> = (props) => {
  const {videos} = props
  const [currentVideoIndex, setCurrentVideoIndex] = React.useState<number>(0)
  const currentVideo = videos ? videos[currentVideoIndex] : null

  const windowSize = Math.min(videos.length, props.windowSize || 4)

  if (!currentVideo) {
    return <>No Videos Configured</>
  }

  return (
    <div className="row justify-content-center">
      <div className="col col-content-container">
        <VideoPlayer iframeSrc={currentVideo.iframeSrc} />
        <div className="d-none d-sm-block">
          <ThumbnailList
            currentVideoIndex={currentVideoIndex}
            videos={videos}
            windowSize={windowSize}
            onSelect={setCurrentVideoIndex}
          />
        </div>
        <div className="d-block d-sm-none">
          <MobileThumbnailList
            currentVideoIndex={currentVideoIndex}
            videos={videos}
            windowSize={windowSize}
            onSelect={setCurrentVideoIndex}
          />
        </div>
      </div>
    </div>
  )
}

export default RegenVideoGallery
