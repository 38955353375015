import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'

/**
 * A hook to listen to window resize events and return the new window width.
 *
 * Returns undefined when server-side rendering (document is undefined)
 */
export function useWindowWidth(delay = 700): number | undefined {
  const [width, setWidth] = useState<number | undefined>(getWindowWidth())

  useEffect(() => {
    const handleResize = () => setWidth(getWindowWidth())
    const debouncedHandleResize = debounce(handleResize, delay)

    if (typeof window != 'undefined') {
      window.addEventListener('resize', debouncedHandleResize)

      return () => {
        window.removeEventListener('resize', debouncedHandleResize)
      }
    }
  }, [delay, (typeof window)])

  return width
}

function getWindowWidth() {
  if (typeof document != 'undefined') {
    return document.documentElement?.clientWidth
  }
}
