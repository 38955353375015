import { Stringable } from '../../util'

/**
 * A hit from the messages_production Algolia index
 */
export interface IPorchMessageHit {
  'slug': Exclude<string, ''>,

  'title': string,
  'subtitle': string,
  'description': string,
  'date': string,
  'date_timestamp': number,
  'date_year': string,
  'duration': number | null,
  'series_id': number,
  'series_position': number,
  'series_title': string,
  'series_type': {
    'key': string,
  },
  'tags': string[]
  'primary_tag'?: { 'id': Stringable, name: string, short_code: string | null }
  'topics': string[]
  'ministries': string[]
  'featured_series_title': string | null,
  'has_transcript': boolean,
  'has_video': boolean,
  'speakers': Array<{
      'speaker_id': number,
      'speaker_name': string,
    }>,
  'scripture': [],
  'transcript_terms': [],
  'primary_topic': string,
  'primary_topic_short': string,
  'thumbnail_url': string,
  'image': string,
  'featured': boolean,
  'media_types': string[]
  'objectID': string,
}

type MessageHrefInfo =
  Pick<IPorchMessageHit, 'objectID' | 'slug'>
type PodcastHrefInfo =
  Pick<IPorchMessageHit, 'objectID' | 'slug' | 'series_type' | 'series_id'>

export function messageHref(message: PodcastHrefInfo | MessageHrefInfo, basePath?: string): string {
  if (!message) { throw new Error(`No message hit given!`) }

  if ('series_type' in message && message.series_type?.key == 'podcast') {
    return `${basePath || ''}/${message.objectID}-${message.slug}`
  }

  return `${basePath || ''}/messages/${message.objectID}-${message.slug}`
}
