import classNames from 'classnames'
import React, { FC } from 'react'
import { IPorchMessageHit, messageHref } from '../../models/algolia/messages_production'
import { Carousel, Thumbnail } from '../shared/porch'
import { breakpoints } from '../shared/porch'

/**
 * A Card can come from Contentful configuration OR it can be a Message hit
 * from Algolia
 */
export type ICard = IConfiguredCard | MessageHitWithCardFields

/** Extends the message hit with fake fields so it's easier to work with below */
export type MessageHitWithCardFields = ({
  // It doesn't have any of these fields
  imageOnly?: undefined
  overline?: undefined
  subhead?: undefined,
  // But if we later added them then the & here would override the above declarations
} & IPorchMessageHit)

export interface IConfiguredCard {
  url?: string
  overline?: string
  title: string
  subhead?: string
  description?: string
  image?: string
  thumbnail_url?: string
  imageOnly?: boolean
  speakers?: [{
    speaker_id: number,
    speaker_name: string,
  }],
  date?: string
}

export interface IPorchCardsCarouselItemsProps {
  items: ICard[]
  imagePosition?: 'top' | 'bottom'
  navPosition?: 'top' | 'bottom'
  viewMoreText?: string
  slidesToShow?: number
  slidesToShowMd?: number
  slidesToShowSm?: number
}

export const PorchCardsCarouselItems: FC<IPorchCardsCarouselItemsProps> = ({
  items,
  imagePosition = 'top',
  navPosition = 'top',
  viewMoreText = 'Watch',
  slidesToShow = 3,
  slidesToShowMd = 2,
  slidesToShowSm = 1,
}) => {
  if (!items || !items.length) {
    return null
  }

  return (
    <Carousel
      className={
        classNames(
          'porch-carousel--cards', `porch-carousel--nav-${navPosition} porch-carousel--image-${imagePosition}`,
        )
      }
      sliderSettings={{
        slidesToShow,
        responsive: [
          {
            breakpoint: breakpoints.md,
            settings: {
              slidesToShow: slidesToShowMd,
            },
          },
          {
            breakpoint: breakpoints.sm,
            settings: {
              slidesToShow: slidesToShowSm,
            },
          },
        ],
      }}
    >
      {items.map((item) => {
        const thumb = item.thumbnail_url?.replace('http://', 'https://')

        return <article key={'slug' in item ? item.slug : item.url} className="card">
          {thumb && (
            <>
              {'slug' in item || item.url ? (
                <a href={'slug' in item ? messageHref(item) : item.url}
                  className="card-image card-img-top stretched-link">
                  <Thumbnail src={thumb} alt={item.title} viewMoreText={viewMoreText} />
                </a>
              ) : null}

              {!('slug' in item) && !item.url ? <Thumbnail src={thumb} alt={item.title} /> : null}
            </>
          )}
          {item.image && (
            <>
              {'slug' in item || item.url ? (
                <a href={'slug' in item ? '/messages/' + item.objectID + '-' + item.slug : item.url}
                  className="card-image card-img-top stretched-link">
                  <Thumbnail src={item.image} alt={item.title} viewMoreText={viewMoreText} />
                </a>
              ) : null}

              {!('slug' in item) && !item.url ? <Thumbnail src={item.image} alt={item.title} /> : null}
            </>
          )}

          {!item.imageOnly && (item.title || item.description || item.speakers) && (
            <div className="card-body">
              {item.overline && <p className="overline">{item.overline}</p>}
              {item.title && <h3 className="h6 card-title">{item.title}</h3>}
              {item.subhead && <p>{item.subhead}</p>}
              {item.description && (
                <div className="card-text">
                  <div className="formatted-content" dangerouslySetInnerHTML={{ __html: item.description }} />
                </div>
              )}
              {item.speakers && item.date && (
                <div className="card-text">
                  <div className="formatted-content">
                    {item.speakers.map((speaker) => speaker.speaker_name).join(', ') + ' // ' + item.date}
                  </div>
                </div>
              )}
            </div>
          )}
        </article>
      })}
    </Carousel>
  )
}
