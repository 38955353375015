import bugsnagClient from 'bugsnag'
import * as React from 'react'

// tslint:disable:max-classes-per-file

type ErrorHandlingComponent = React.Component<any, {
  error?: Error | null,
  wait?: boolean,
}>

interface IErrorHandlingOptions {
  propagate?: boolean
  preventDefault?: boolean
}

type AsyncMethod = (...args: any[]) => Promise<any>

/**
 * Wraps an async handler function in an error handler, which manages the "wait" and
 * "error" properties on the given component's state.
 * @param component The component whose state to set when an error occurs
 * @param fn The async error handling function to bind
 */
export function withErrorHandling<F extends AsyncMethod>(
  component: ErrorHandlingComponent,
  fn: F,
  options?: IErrorHandlingOptions,
) {
  return new AsyncErrorHandler(component).wrap(component, fn, options)
}

/**
 * Wraps async functions to handle thrown errors and forward them to the given
 * ErrorHandlingComponent
 */
export class AsyncErrorHandler {
  constructor(private target: ErrorHandlingComponent) {
    this.setTarget = this.setTarget.bind(this)
  }

  /**
   * Returns an error handling wrapper function which forwards any errors to the target
   * component
   * The returned wrapper function will log any async errors from the source component
   * and then set the state on the target component.
   * @param source The component whose function should be wrapped
   * @returns A wrapped version of that function
   */
  public wrap<F extends AsyncMethod>(
    source: React.Component,
    fn: F,
    opts?: IErrorHandlingOptions,
  ): F {

    const options = Object.assign({
      propagate: false,
      preventDefault: true,
    }, opts)

    const name = getMethodName(source, fn)
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this

    return (async (...args) => {
      const evt = (args[0] && typeof args[0] == 'object' && 'preventDefault' in args[0]) ? args[0] : null
      if (options.preventDefault && evt) {
        evt.preventDefault()
      }
      if (evt && 'persist' in evt) {
        // https://reactjs.org/docs/events.html#event-pooling
        evt.persist()
      }

      bugsnagClient.leaveBreadcrumb('async method begin', {
        name,
        component: source.constructor.name,
        event: evt && formatEvent(evt),
      })
      setState({ wait: true })

      try {
        const result = await fn.apply(source, args)

        bugsnagClient.leaveBreadcrumb('async method end', {
          name,
          component: source.constructor.name,
        })
        setState({ error: null, wait: false })
        return result

      } catch (exception) {
        // for some reason the shopify client throws an array of errors.
        const errors = Array.isArray(exception) ? exception : [exception]
        let ex: Error | string
        for (ex of errors) {
          console.error(ex)
          try {
            bugsnagClient.notify(ex, {
              metaData: {
                name,
                component: source.constructor.name,
                props: source.props,
                state: source.state,
                args,
              },
            })
          } catch (e) {
            console.error('Unable to notify bugsnag!', e)
          }
        }
        ex = errors[0]
        if (typeof ex == 'string') {
          ex = new Error(ex)
        }

        setState({ error: ex, wait: false })

        if (options.propagate) {
          throw ex
        }
      }

      function setState(state: ErrorHandlingComponent['state']) {
        if (self.target) { self.target.setState(state) }
        if (source && source !== self.target) {
          source.setState(state)
        }
      }
    }) as F
  }

  /**
   * Sets the top-level target to a new element.
   * Useful in a ref:
   *
   * @example
   * ```
   *   public render() {
   *     return <div>
   *       <MyErrorHandler ref={this.errorHandler.setTarget} />
   *       <OtherAsyncComponent errorHandler={this.errorHandler} />
   * ```
   */
  public setTarget(element: ErrorHandlingComponent) {
    this.target = element
  }
}

function getMethodName(obj: any, method: any): string {
  let methodName = null
  Object.getOwnPropertyNames(obj).forEach((prop) => {
    if (obj[prop] === method) {
      methodName = prop
    }
  })

  if (methodName !== null) {
    return methodName
  }

  const proto = Object.getPrototypeOf(obj)
  if (proto) {
    return getMethodName(proto, method)
  }
  return '(unknown)'
}

function formatEvent(evt: MouseEvent) {
  const target: any = evt.target
  return {
    type: evt.type,
    target: target && {
      id: target.id,
      tagName: target.tagName,
      title: target.title,
    },
    detail: evt.detail,
  }
}
