import * as React from 'react'
import { connectRefinementList, RefinementListExposed, RefinementListProvided } from 'react-instantsearch-core'

type IProps = RefinementListExposed & {
  hasFacets: boolean
  translations: {
    noResults: string,
    searchReset: string,
    blog_facet_select: {
      see_all_option: string,
    },
    searchBox: {
      placeholder: string,
    },
  },
}

interface IMenuItem {
  label: string
  value: string[]
  count?: number
  isRefined?: boolean
}

interface IState {
  hasFacets: boolean
}

class RefinementsDropdown extends React.Component<IProps & RefinementListProvided, IState> {
  private domId: string

  constructor(props: RefinementsDropdown['props'], context?: any) {
    super(props, context)

    this.state = {
      hasFacets: this.props.hasFacets,
    }

    this.domId = `blog_facet_${Math.floor(Math.random() * 10000)}`

    this.refine = this.refine.bind(this)
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.hasFacets !== prevProps.hasFacets) {
      this.setState({
        hasFacets: this.props.hasFacets,
      })
    }
  }

  public render() {
    const { translations } = this.props
    return (
      <div className="dropdown">
        <button
          className={
            'btn btn-outline-secondary btn-lg dropdown-toggle ' +
            `${this.optionsDisplayClass()}`
          }
          type="button"
          id={this.domId}
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="current-refinement">
            {translations.blog_facet_select?.see_all_option}
          </span>
        </button>
        <div
          className="dropdown-menu dropdown-menu-right rounded-0"
          style={{ minWidth: '14em' }}
          aria-labelledby={this.domId}>
          <div className="facets-wrapper">
            {this.props.items.map((item) => this.renderOption(item))}
          </div>
        </div>
      </div>
    )
  }

  private refine(e: React.MouseEvent<any>, value: string[]) {
    e.preventDefault()

    this.props.refine(value)
  }

  private optionsDisplayClass() {
    if (this.state.hasFacets || this.props.currentRefinement) {
      return null
    }
    return 'disabled'
  }

  private renderOption(item: IMenuItem) {
    const props = {
      className: `dropdown-item d-flex justify-content-between`,
      onClick: (e: React.MouseEvent<any>) => this.refine(e, item.value),
    }

    return <button {...props} key={`facet-option=${item.value}`}>
      <span className="refinement">{item.label}</span>
      <input type="checkbox" checked={item.isRefined} readOnly={true} />
    </button>
  }
}

const ConnectedRefinementsDropdownWithoutDefaults = connectRefinementList(RefinementsDropdown)

const ConnectedRefinementsDropdown = ({attribute, ...rest}: IProps) =>
  <ConnectedRefinementsDropdownWithoutDefaults
    attribute={attribute}
    limit={100}
    transformItems={(items) => items.sort((a: any, b: any) => ByLabel(a, b))}
    {...rest}
  />

const ByLabel = (a: any, b: any) => (
  a.label.toLowerCase().localeCompare(b.label.toLowerCase())
)

export default ConnectedRefinementsDropdown
