import * as React from 'react'
import { connectMenu, MenuExposed, MenuProvided } from 'react-instantsearch-core'

type ISearchFacetProps = MenuExposed & {
  attributeTitle: string,
  acceptedValues?: Array<{ key: string, title: string }>,
  sort?: boolean | 'asc' | 'desc',
}

interface IState {
  hasFacets: boolean
}

class SearchFacetMenu extends React.Component<ISearchFacetProps & MenuProvided, IState> {
  private domId: string

  constructor(props: SearchFacetMenu['props']) {
    super(props)

    this.domId = `search_facet_${Math.floor(Math.random() * 10000)}`

    this.refine = this.refine.bind(this)
  }

  public render() {
    const { currentRefinement, attributeTitle, acceptedValues } = this.props

    const title = getTitleOf(currentRefinement, acceptedValues) || currentRefinement

    return <div className="dropdown">
      <button
        className={
          'btn btn-outline-secondary btn-lg btn-block ' +
          'dropdown-toggle'
        }
        type="button"
        id={this.domId}
        data-display="static"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span className="current-refinement">{title || attributeTitle}</span>
      </button>
      <div
        className="dropdown-menu dropdown-menu-right rounded-0"
        aria-labelledby={this.domId}>
        <div className="facet-wrapper">
          {this.props.items.map((item) => this.renderOption(item))}
        </div>
        {this.props.currentRefinement && this.renderShowAll()}
      </div>
    </div>
  }

  private refine(e: React.MouseEvent<any>, value: string) {
    e.preventDefault()

    this.props.refine(value)
  }

  private renderOption(item: Partial<MenuProvided['items'][0]>) {
    const title = getTitleOf(item.label, this.props.acceptedValues) || item.label
    const value = item.value || ''

    const props = {
      className: `dropdown-item ${item.isRefined ? 'active' : ''}`,
      onClick: (e: React.MouseEvent<any>) => !item.isRefined && this.refine(e, value),
    }

    return <button {...props} key={`${this.domId}-facet=${value}`}>
      <span className="refinement">{title}</span>
    </button>
  }

  private renderShowAll() {
    return <>
      <div className="dropdown-divider"></div>
      {this.renderOption({ label: 'See All' })}
    </>
  }
}

const ConnectedSearchFacetMenu = connectMenu(SearchFacetMenu)

const SearchFacet = ({attribute, acceptedValues, ...rest}: ISearchFacetProps) =>
  <ConnectedSearchFacetMenu
    attribute={attribute}
    limit={rest.limit || 999}
    acceptedValues={acceptedValues}
    transformItems={(items: Array<{label: string}>) => {
      if (acceptedValues) {
        items = items.filter(({label}) => acceptedValues.find(({key}) => label == key))
      }

      items = items.slice(0, rest.limit)

      if (rest.sort === false) { return items }

      const sorted = items.sort((a, b) => ByTitle(a, b, acceptedValues))

      return rest.sort === 'desc' ? sorted.reverse() : sorted}
    }
    {...rest}
  />

const ByTitle = (
  a: {label: string},
  b: {label: string},
  acceptedValues: Array<{ key: string, title: string }> | undefined,
) => {
  const aLabel = getTitleOf(a.label, acceptedValues) || a.label
  const bLabel = getTitleOf(b.label, acceptedValues) || b.label

  return aLabel.toLowerCase().localeCompare(bLabel.toLowerCase())
}

function getTitleOf(
  key: string | undefined,
  acceptedValues: Array<{ key: string, title: string }> | undefined,
): string | undefined {
  return acceptedValues?.find((v) => v.key == key)?.title
}

export { SearchFacet }
