import { clearRecurring, RecurrenceTimeout, setRecurring } from 'async-toolbox'
import parseISO from 'date-fns/parseISO'
import React from 'react'

import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { assert, Stringable } from '../../util'
import { AsyncErrorHandler } from '../../util/async-error-handler'
import { ConnectionError, getLiveStream, ILiveStream, IStreamEvent } from '../live-stream'
import { Timer } from './timer'
import { CountdownContainer, topOfMinute } from './util'

interface IProps {
    id: Stringable
    labelText: string
    backgroundImageUrl?: string

    onRefresh?: () => void
    onExpiry?: () => void

    fetch?: typeof window.fetch
    t: ITranslationsProvided['t']
}
interface IState {
    liveStream?: ILiveStream
    currentEvent?: IStreamEvent
    isLive?: boolean

    error?: Error
    persistentError?: boolean
}

export class LiveStreamCountdown extends React.Component<IProps, IState> {
  private interval?: RecurrenceTimeout
  private _fetch: typeof window.fetch
  private errorHandler: AsyncErrorHandler
  private persistentErrorTimeout?: number

  constructor(props: IProps) {
    super(props)

    const _fetch = props.fetch || (typeof window != 'undefined' ? window.fetch : undefined)
    assert(_fetch, 'Unable to find a Fetch library')
    this._fetch = _fetch
    this.state = {}
    this.errorHandler = new AsyncErrorHandler(this)
    this.refresh = this.errorHandler.wrap(this, this.refresh)
  }

  public async componentDidMount() {
    await this.refresh()
    this.interval = setRecurring(this.refresh, 15000)
  }

  public componentWillUnmount() {
    clearRecurring(this.interval)
    clearTimeout(this.persistentErrorTimeout)
  }

  public componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.state.currentEvent && !prevState.currentEvent) {
      if (this.props.onExpiry) {
        this.props.onExpiry()
      }
    }
    if (this.state.error && !prevState.error) {
      // start checking every 5 sec instead of every 15
      clearRecurring(this.interval)
      this.interval = setRecurring(this.refresh, 5000)
      // after 15 seconds (2x 5s interval, 3 total errors in a row) notify the user
      this.persistentErrorTimeout = setTimeout(() => {
        if (this.state.error) {
          this.setState({
            persistentError: true,
          })
        }
      }, 15000)
    } else if (!this.state.error && this.state.persistentError) {
      clearTimeout(this.persistentErrorTimeout)
      // go back to checking every 15 seconds
      clearRecurring(this.interval)
      this.interval = setRecurring(this.refresh, 15000)
      this.setState({
        persistentError: false,
      })
    }
  }

  public render() {
    const { t } = this.props
    const { liveStream, currentEvent, error, persistentError } = this.state

    if (error && !(error instanceof ConnectionError)) {
      // bail on non-connection errors
      throw error
    }

    if (!liveStream) {
      return <CountdownContainer>
        <h2 className="countdown-timer__label-text">{this.props.labelText}</h2>
        <div className="loading-bar" />
        {persistentError &&
                    <div className="error">{t('connectionError')}</div>}
      </CountdownContainer>
    }

    const { next_event } = liveStream
    if (currentEvent) {
      return this.renderEmbed(currentEvent)
    }

    if (next_event) {
      return <Timer
        key={`ls-${liveStream.id}-timer-ending-${next_event.starts_at}`}
        target={topOfMinute(parseISO(next_event.starts_at))}
        onExpiry={this.refresh}
        onRefresh={this.props.onRefresh}
        labelText={this.props.labelText}
      >
        {persistentError &&
                    <div className="error">{t('connectionError')}</div>}
      </Timer>
    }

    return <CountdownContainer>
      <h2 className="countdown-timer__label-text">
        {t('streamEnded')}
      </h2>
    </CountdownContainer>
  }

  public async refresh() {
    const liveStream = await getLiveStream(this.props.id, this._fetch)

    if (!liveStream) {
      this.setState({
        error: new Error(`Live stream ${this.props.id} cannot be found!`),
      })
      return
    }

    const newState: Partial<IState> = {
      liveStream,
      currentEvent: liveStream.current_event,
    }

    this.setState(newState)
  }

  private renderEmbed(event: IStreamEvent) {
    return <div className="embed-responsive embed-responsive-16by9"
      dangerouslySetInnerHTML={{ __html: event.embed_code }}
    />
  }
}

export default withTranslations(LiveStreamCountdown, {
  connectionError:
    'We\'re having a little trouble connecting right now. Please doublecheck your internet connection.',
})
