import { HiddenFacets, IRefinementDefinition } from '@watermarkchurch/react-instantsearch-components'
import * as React from 'react'
import { Configure } from 'react-instantsearch-dom'
import AlgoliaSearch, { IInstantSearchProps } from '../algolia-search'
import { ConnectedPorchLatestMessageCarouselResults } from './list-results'

export interface IPorchLatestMessageCarouselProps {
  locationSource: {
    index: string,
    facetFilters?: IRefinementDefinition,
  },
  header: string,
  limit?: number | 5,
  initialState?: IInstantSearchProps['initialState'],
  initialResults?: IInstantSearchProps['initialResults']
}

export const PorchLatestMessageCarousel: React.FC<IPorchLatestMessageCarouselProps> = ({
  locationSource,
  header,
  limit,
  initialState,
  initialResults,
}) => {
  return <AlgoliaSearch
    indexName={locationSource.index}
    widgetName="porch-latest-message-carousel"

    initialState={initialState}
    initialResults={initialResults}
  >
    {locationSource.facetFilters &&
      <HiddenFacets hiddenFacets={locationSource.facetFilters} />}
    <div className="container">
      <div className="porch-latest-message-carousel__padding">
        <header className="section__header">
          <div className="overline">{header}</div>
        </header>
        <Configure hitsPerPage={limit || 3} />
        <div className="porch-latest-message-carousel__container">
          <ConnectedPorchLatestMessageCarouselResults />
        </div>
      </div>
    </div>
  </AlgoliaSearch>
}

export default PorchLatestMessageCarousel
