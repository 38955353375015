import * as PropTypes from 'prop-types'
import * as React from 'react'

interface IProps {
  greeting: string
}

class HelloWorld extends React.Component<IProps> {
  public static propTypes = {
    greeting: PropTypes.string,
  }

  public render() {
    return (
      <React.Fragment>
        Greeting: {this.props.greeting}
      </React.Fragment>
    )
  }
}

export default HelloWorld
