import * as React from 'react'
import { ErrorBoundary } from './components/error-boundary'
import { PrefixTranslations, ProvideTranslations } from './connectors/translation'

export default function wrapTopLevelComponent<P = any>(
  Component: React.ComponentType<P>,
): React.ComponentType<P> {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    let rendered = <Component {...props}></Component>

    if (props.translations) {
      rendered =
        <ProvideTranslations translations={props.translations || {}}>
          {rendered}
        </ProvideTranslations>
    }

    if (props.translationPrefix) {
      rendered =
        <PrefixTranslations prefix={props.translationPrefix}>
          {rendered}
        </PrefixTranslations>
    }

    return <ErrorBoundary>
      {rendered}
    </ErrorBoundary>
  }
}
