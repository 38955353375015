import { format } from 'date-fns'
import * as React from 'react'
import { connectInfiniteHits, InfiniteHitsProvided } from 'react-instantsearch-core'
import { messageHref } from '../../models/algolia/messages_production'

export interface IPorchMediaHit {
  'title': string,
  'subtitle': string,
  'slug': string,
  'description': string,
  'date': string,
  'date_timestamp': number,
  'date_year': string,
  'series_id': number,
  'series_title': string,
  'series_type': {
    'key': string,
  },
  'featured_series_title': string,
  'has_transcript': boolean,
  'has_video': boolean,
  'speakers': [
    {
      'speaker_id': number,
      'speaker_name': string,
    }
  ],
  'scripture': [],
  'transcript_terms': [],
  'primary_topic': string,
  'primary_topic_short': string,
  'thumbnail_url': string,
  'featured': boolean,
  'objectID': string,
}

interface IProps {
  showHasMore: boolean
}

const MediaPostHit: React.FC<{ hit: IPorchMediaHit }> = ({hit}) => {
  const thumb = hit.thumbnail_url?.replace('http://', 'https://')

  return <div className="col-12">
    <div className="card card--split">
      <a href={messageHref(hit)} className="card-img-top stretched-link">
        <div className="thumbnail">
          <img alt={hit.title} className="thumbnail__image" src={thumb} />
          <div className="thumbnail__overlay">
            <span className="thumbnail__text">Watch</span>
            <svg className="thumbnail__circle" height="100" width="100">
              <circle cx="50" cy="50" r="40"></circle>
            </svg>
          </div>
        </div>
      </a>
      <div className="card-body">
        <p className="card-meta text-uppercase">
          {hit.speakers.map((s) => s.speaker_name).join(', ')}
          {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
          <span> // </span>
          {hit.date ?
            format(new Date(hit.date), 'MMM d, yyyy')
            : ''}
          {/* eslint-disable-next-line react/jsx-no-comment-textnodes */}
          <span> // </span>
          {hit.series_title}
        </p>
        <h3 className="h5 card-title">{hit.title}</h3>
        <p className="card-text">{hit.description}</p>
      </div>
    </div>
  </div>
}

const SearchResults: React.FunctionComponent<IProps & InfiniteHitsProvided> = ({
  showHasMore,
  refineNext,
  hasMore,
  hits,
}) => (
  <>
    <div className="card-grid row">
      {hits.map((hit) =>
        <MediaPostHit key={hit.objectID} hit={hit} />)}
    </div>

    {showHasMore && hasMore && (
      <div className="row justify-content-center">
        <div className="col col-content-container">
          <button
            className="d-block btn btn-info mx-auto mt-5"
            onClick={refineNext}
          >
            Load More Messages
          </button>
        </div>
      </div>
    )}
  </>
)

const ConnectedSearchResults = connectInfiniteHits(SearchResults)

export default ConnectedSearchResults
