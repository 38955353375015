import * as React from 'react'
import {
  connectCurrentRefinements,
  CurrentRefinementsExposed,
  CurrentRefinementsProvided,
  RefinementValue,
} from 'react-instantsearch-core'

type IProps = CurrentRefinementsExposed

interface IRefinementItem {
  label: string
  value: RefinementValue[]
}

interface IRefinement {
  items: IRefinementItem[]
}

const RefinementButton = ({
  item,
  refine,
}: {
  item: IRefinementItem;
  refine: CurrentRefinementsProvided['refine'];
}) => {
  const handleClear = React.useCallback(
    (e) => {
      e.preventDefault()
      refine(item.value)
    },
    [item, refine],
  )

  return (
    <button className="btn btn-info" onClick={handleClear}>
      {item.label}
    </button>
  )
}

const CurrentRefinements: React.FunctionComponent<
  IProps & CurrentRefinementsProvided
> = ({ items, refine }) => {
  return (
    <div>
      {items.map((refinement) => (
        <div key={refinement.id} className="refinement-close-list">
          {(refinement as unknown as IRefinement).items.map((item) => (
            <RefinementButton key={item.label} item={item} refine={refine} />
          ))}
        </div>
      ))}
    </div>
  )
}

const ConnectedCurrentRefinements = connectCurrentRefinements(
  CurrentRefinements,
)

export default ConnectedCurrentRefinements
