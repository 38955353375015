import { HiddenFacets, IRefinementDefinition } from '@watermarkchurch/react-instantsearch-components'
import * as React from 'react'
import { Configure } from 'react-instantsearch-dom'
import AlgoliaSearch from '../algolia-search'
import { ConnectedPorchCardsCarouselResults } from './list-results'

export interface IProps {
  locationSource: {
    index: string,
    facetFilters?: IRefinementDefinition,
    operator: 'or' | 'and' | undefined,
  },
  header: string,
  limit?: number,
}

export const PorchCardsCarousel: React.FC<IProps> = ({ locationSource, header, limit }) => {

  return  <AlgoliaSearch
    indexName={locationSource.index}
    widgetName="porch-cards-carousel"
  >
    {locationSource.facetFilters &&
      <HiddenFacets hiddenFacets={locationSource.facetFilters} operator={locationSource.operator} />}
    <div className="container">
      <div className="porch-cards-carousel__padding">
        <Configure hitsPerPage={limit || 3} />
        <div className="porch-cards-carousel__container">
          <header className="section__header">
            <h2>{header}</h2>
          </header>
          <ConnectedPorchCardsCarouselResults />
        </div>
      </div>
    </div>
  </AlgoliaSearch>
}

export default PorchCardsCarousel
