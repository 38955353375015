import {HiddenFacets} from '@watermarkchurch/react-instantsearch-components'
import * as React from 'react'
import { Configure } from 'react-instantsearch-dom'
import { Stringable } from '../../util'
import AlgoliaSearch from '../algolia-search'
import ConnectedSearchResults from './search-results'

interface IProps {
  blogSource: IAlgoliaBlogSource

  title?: string
  ctaText?: string
  ctaLink?: string
  limit?: number
  columns?: number
  showHasMore?: boolean
}

interface IAlgoliaBlogSource {
  source: 'Algolia'
  index: string
  facetFilters?: {
    [key: string]: Stringable[] | Stringable;
  }
  operator?: 'or' | 'and'
}

const RegenBlogGallery: React.FunctionComponent<IProps> = ({
  blogSource,
  limit,
  columns = 3,
  title,
  ctaText,
  ctaLink,
  showHasMore = false,
}) => {
  return (
    <AlgoliaSearch indexName={blogSource.index} widgetName="regen-blog-gallery">
      <HiddenFacets hiddenFacets={{ object_type: 'Post' }} />
      {blogSource.facetFilters && (
        <HiddenFacets
          hiddenFacets={blogSource.facetFilters}
          operator={blogSource.operator}
        />
      )}
      <Configure hitsPerPage={limit || 3} />

      <div className="section-card-deck regen-subheadline-with-utility-menu-card-deck">
        {title ? (
          <div className="row justify-content-center section-description">
            <div className="col-content-container">
              <div className="row">
                <div className="col">
                  <h2
                    className="section-title"
                    dangerouslySetInnerHTML={{ __html: title }}
                  />
                </div>
                {ctaText && ctaLink ? (
                  <div className="section-utility-nav col-md-4">
                    <a
                      className="btn-primary text-only btn btn-md text-nowrap"
                      href={ctaLink}
                    >
                      {ctaText}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}

        <ConnectedSearchResults columns={columns} showHasMore={showHasMore} />
      </div>
    </AlgoliaSearch>
  )
}

export default RegenBlogGallery
