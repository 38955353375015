import { HiddenFacets } from '@watermarkchurch/react-instantsearch-components'
import * as React from 'react'
import { Configure, connectSearchBox } from 'react-instantsearch-dom'
import { Stringable } from '../../util'
import AlgoliaSearch from '../algolia-search'
import { ConnectedPorchLatestMessageResults } from './list-results'

export interface IPorchLatestMessageProps {
  messageSource: {
    index: string,
    query: string,
    facetFilters?: {
      [key: string]: Stringable[] | Stringable,
    }
    operator?: 'or' | 'and',
  },
}

const VirtualSearchBox = connectSearchBox(() => null)

export const PorchLatestMessage: React.FC<IPorchLatestMessageProps> = ({ messageSource }) => {
  return <AlgoliaSearch
    indexName={messageSource.index}
    widgetName="porch-latest-message"
    storeStateInQuery={true}
  >
    {messageSource.facetFilters &&
      <HiddenFacets hiddenFacets={messageSource.facetFilters}
        operator={messageSource.operator} />}

    {messageSource.query &&
      <VirtualSearchBox defaultRefinement={messageSource.query} />}

    <div className="container">
      <div className="porch-latest-message__padding">
        <Configure hitsPerPage={1} />
        <ConnectedPorchLatestMessageResults />
      </div>
    </div>
  </AlgoliaSearch>
}

export default PorchLatestMessage
