import { format } from 'date-fns'
import * as React from 'react'
import { connectInfiniteHits, InfiniteHitsProvided } from 'react-instantsearch-core'
import { IPapyrusAlgoliaPost } from '../../models/papyrus'
import { rewriteContentfulImageUrl } from '../../util/contentfulImage'

interface IProps {
  showHasMore: boolean
}

const BlogPostHit: React.FC<{ hit: IPapyrusAlgoliaPost }> = ({hit}) => {
  const thumbURL = hit.heroImage ? rewriteContentfulImageUrl(hit.heroImage.file.url, { w: 750, fm: 'webp' }) : ''

  return <div className="col-12 col-sm-6 col-md-4">
    <div className="card">
      <a href={'/blog' + hit.slug} className="card-img-top stretched-link">
        <div className="thumbnail">
          <img alt="card image" className="thumbnail__image" src={thumbURL} />
          <div className="thumbnail__overlay">
            <span className="thumbnail__text">Read</span>
            <svg className="thumbnail__circle" height="100" width="100">
              <circle cx="50" cy="50" r="40"></circle>
            </svg>
          </div>
        </div>
        <div className="card-body mt-2">
          <p className="card-meta overline mb-1">
            {hit.date ?
              format(new Date(hit.date), 'MMM d, yyyy')
              : ''}
            <span> / </span>
            {hit.readTime ?
              Math.ceil(hit.readTime) + ' min'
              : ''}
          </p>
          <h3 className="h5">{hit.title}</h3>
          <p>{hit.author?.fullName}</p>
          <p className="card-text">{hit.summary?.summary}</p>
        </div>
      </a>
    </div>
  </div>
}

const SearchResults: React.FunctionComponent<IProps & InfiniteHitsProvided> = ({
  showHasMore,
  refineNext,
  hasMore,
  hits,
}) => (
  <>
    <div className="card-grid row">
      {hits.map((hit) =>
        <BlogPostHit key={hit.objectID} hit={hit} />)}
    </div>

    {showHasMore && hasMore && (
      <div className="row justify-content-center">
        <div className="col col-content-container">
          <button
            className="d-block btn btn-info mx-auto mt-5"
            onClick={refineNext}
          >
            Load More Articles
          </button>
        </div>
      </div>
    )}
  </>
)

const ConnectedSearchResults = connectInfiniteHits(SearchResults)

export default ConnectedSearchResults
