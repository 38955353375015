import React from 'react'
// tslint:disable-next-line:match-default-export-name
import {WatermarkMediaDefault} from '@watermarkchurch/react-instantsearch-components'
import { IListResponse } from '../../models/media'
import { Stringable } from '../../util'
import {IConfiguredCard, PorchCardsCarouselItems} from '../porch-cards-carousel-items'

interface IProps {
  publishing_target?: string
  filters?: IFilters
}

interface IFilters {
  tag_id?: Stringable | Stringable[],
  speaker_id?: Stringable | Stringable[],
  scripture_book_id?: Stringable | Stringable[],
  title_like?: Stringable | Stringable[],

  begin_on_or_before_date?: Stringable
  begin_on_or_after_date?: Stringable
  end_on_or_before_date?: Stringable
  end_on_or_after_date?: Stringable
}

const defaultItems: IConfiguredCard[] = [
  {
    title: ' ',
    image: WatermarkMediaDefault,
    description: ' ',
  },
  {
    title: ' ',
    image: WatermarkMediaDefault,
    description: ' ',
  },
  {
    title: ' ',
    image: WatermarkMediaDefault,
    description: ' ',
  },
  {
    title: ' ',
    image: WatermarkMediaDefault,
    description: ' ',
  },
]

export default function PorchRecentSeriesCarousel({publishing_target, filters}: IProps) {

  const [items, setItems] = React.useState(defaultItems)
  const [error, setError] = React.useState<Error>()

  React.useEffect(() => {
    loadSeries(publishing_target || 'web', filters)
      .then((cards) => setItems(cards))
      .catch((ex) => setError(ex))
  }, [])

  if (error) {
    throw error
  }

  return <PorchCardsCarouselItems
    items={items}
    viewMoreText={'Watch'} />
}

async function loadSeries(target: string, filters?: IProps['filters']): Promise<IConfiguredCard[]> {
  const params = new URLSearchParams()
  params.set('target', target)
  if (filters) {
    (Object.keys(filters) as Array<keyof IFilters>).forEach((key) => {
      const value = filters[key]
      if (!value) { return }
      if (Array.isArray(value)) {
        value.forEach((val) => {
          params.append(`filter[${key}][]`, val.toString())
        })
      } else {
        params.set(`filter[${key}]`, value.toString())
      }
    })
  }

  // Use the local proxy
  const resp = await fetch(`/api/v1/series?${params.toString()}`, {
    redirect: 'follow',
  })
  if (resp.status >= 400) {
    throw new Error(`Unexpected status ${resp.status} for /api/v1/series?target=${target}`)
  }

  const json: IListResponse<'series'> = await resp.json()

  return json.series.map((s) => {
    return {
      id: s.id,
      title: s.title,
      url: `/messages/series/${s.id}`,
      subhead: `${s.messages_count} Messages in Series`,
      image: s.images && s.images.wide && s.images.wide.url,
    }
  })
}
