import React, { FC, useEffect, useState } from 'react'
import { getRandomIndex } from '../shared/helpers'
import { useInterval, useWindowWidth } from '../shared/hooks'
import { breakpoints, Breakpoints, BreakpointSize } from '../shared/porch/util'

export interface IMosaicItem {
  id: string
  title: string
  description?: string
  imageURL: string
}

export interface IPorchMosaicCarouselOptions {
  slotCount: Partial<Breakpoints> & { [BreakpointSize.XS]: number }
  interval: number
  transition: 'fade' | 'swipe'
}

export interface IPorchMosaicCarouselProps {
  items: IMosaicItem[]
  options?: Partial<IPorchMosaicCarouselOptions>
}

export const PorchMosaicCarousel: FC<IPorchMosaicCarouselProps> = ({ items, ...props }) => {
  const defaultOptions = {
    // Allow overriding all breakpoints for slot count.
    slotCount: props.options?.slotCount || { xs: 3, md: 4 },
    interval: 3000,
    transition: 'fade',
  }

  // Slot count should not exceed the number of items.
  const maxSlotCount = items.length

  // fallback to 'xs' for mobile when prerendering and window is not defined
  const windowWidth = useWindowWidth(300) || breakpoints.xs

  const options: IPorchMosaicCarouselOptions = Object.assign({}, defaultOptions, props.options)
  const initialSlotCount = getSlotCountForCurrentBreakpoint()
  const initialSlots = Array.from(Array(initialSlotCount).keys())

  const [slotCount, setSlotCount] = useState(initialSlotCount)
  const [slots, setSlots] = useState<number[]>(initialSlots)
  const [prevChangedSlotIndex, setPrevChangedSlotIndex] = useState<number>(-1)

  // Update a slot to a random item index.
  const updateSlot = (slotIndex?: number) => {
    // Prevent updating slots if we have equal or fewer items than slots.
    if (maxSlotCount <= slotCount) {
      return
    }

    // Either use the given slotIndex or pick a random slot to update.
    const slotToUpdate =
      typeof slotIndex !== 'undefined'
        ? slotIndex
        : getRandomIndex(
          Array.from(Array(slotCount).keys()),
          prevChangedSlotIndex > -1 ? [prevChangedSlotIndex] : undefined,
        )
    const newSlots = [...slots]

    // Select a random item to insert into the slot.
    const randomItemIndex = getRandomIndex(items, slots)

    newSlots[slotToUpdate] = randomItemIndex

    // Avoid changing the same slot twice in a row.
    setPrevChangedSlotIndex(slotToUpdate)

    // Update the slots to the new state.
    setSlots(newSlots)
  }

  // Fill the slots based on the slot count.
  const fillEmptySlots = () => {
    // Adjust the slot length to match the slot count.
    const newSlots = [...slots].slice(0, slotCount)

    // Fill any empty slots with random items.
    for (let i = 0; i < slotCount; i++) {
      if (typeof newSlots[i] === 'undefined') {
        newSlots[i] = getRandomIndex(items, newSlots)
      }
    }

    // Update the slots to the new state.
    setSlots(newSlots)
  }

  // Update the slot count to match a specific breakpoint.
  const updateSlotCountToMatchBreakpoint = () => setSlotCount(getSlotCountForCurrentBreakpoint())

  function getSlotCountForCurrentBreakpoint() {
    const slotCountForBreakpoint = Object.keys(breakpoints).reduce((acc, breakpoint) => {
      return windowWidth >= breakpoints[breakpoint as BreakpointSize]
        ? options.slotCount[breakpoint as BreakpointSize] || acc
        : acc
    }, 0)

    // Slot count should not exceed the max slot count.
    return Math.min(slotCountForBreakpoint, maxSlotCount)
  }

  // Update the slot count when the window resizes.
  useEffect(() => {
    updateSlotCountToMatchBreakpoint()
  }, [windowWidth])

  // Fill the slots when the slot count changes.
  useEffect(() => {
    fillEmptySlots()
  }, [slotCount])

  // Use an interval to update a random slot with a random item index.
  useInterval(updateSlot, options.interval)

  if (!items.length) {
    return null
  }

  return (
    <div className={`mosaic-carousel mosaic-carousel--transition-${options.transition}`}>
      {slots.map((slot, slotIndex) => (
        <div key={slotIndex} className="mosaic-carousel__slot">
          {items.map((item, itemIndex) => (
            <figure
              key={item.id}
              className={`mosaic-carousel__image${slot === itemIndex ? ' mosaic-carousel__image--active' : ''}`}
              style={{ backgroundImage: `url(${item.imageURL})` }}
            >
              {item.description && <figcaption className="sr-only">{item.description}</figcaption>}
            </figure>
          ))}
        </div>
      ))}
    </div>
  )
}
