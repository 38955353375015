import { HiddenFacets, IRefinementDefinition } from '@watermarkchurch/react-instantsearch-components'
import * as React from 'react'
import { Configure, connectStats } from 'react-instantsearch-dom'
import AlgoliaSearch from '../algolia-search'
import { ConnectedPorchLocationListResults } from './list-results'

export interface IPorchLocationListProps {
  locationSource: {
    index: string,
    facetFilters?: IRefinementDefinition,
    operator?: 'or' | 'and',
  },
  header: string,
}

const HitCount = ({ nbHits }: any) => <span className="porch-location-list__hits-count">({nbHits})</span>

const HitCountStat = connectStats(HitCount)

export const PorchLocationList: React.FC<IPorchLocationListProps> = ({ locationSource, header }) => {
  return  <AlgoliaSearch
    indexName={locationSource.index}
    widgetName="porch-location-list"
    storeStateInQuery={true}
  >
    {locationSource.facetFilters && <HiddenFacets hiddenFacets={locationSource.facetFilters} />}
    <div className="container">
      <div className="row mb-4">
        <div className="col-12 porch-location-list__header">
          {header && <h2>
            {header} <HitCountStat />
          </h2>}
        </div>
      </div>
    </div>
    <div className="container mb-5">
      <Configure hitsPerPage={99} aroundLatLngViaIP={true} aroundRadius="all" />
      <ConnectedPorchLocationListResults />
    </div>
  </AlgoliaSearch>
}

export default PorchLocationList
