import { SearchBox } from '@watermarkchurch/react-instantsearch-components'
import algoliasearch from 'algoliasearch'
import * as React from 'react'
import { connectStateResults, StateResultsProvided } from 'react-instantsearch-core'
import { Configure, RefinementList } from 'react-instantsearch-dom'
import WCC from 'wcc'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { Stringable } from '../../util'
import AlgoliaSearch from '../algolia-search'
import ConnectedSearchResults from '../regen-stories-list/search-results'
import ResultBlock from './result-block'

const staticRefinements = ['Articles', 'Stories', 'Struggles']

interface IProps extends ITranslationsProvided {
  blogSource: BlogSource
}

type BlogSource = IAlgoliaBlogSource

interface IAlgoliaBlogSource {
  source: 'Algolia'
  index: string
  facetFilters?: {
    [key: string]: Stringable[] | Stringable,
  }
  operator?: 'or' | 'and'
}

const transformRefinementItems = (items: Array<{ label: string }>) => (
  staticRefinements.map((label) => {
    const existingItem = items.find((item) => item.label === label)

    if (existingItem) {
      return existingItem
    }

    return {
      label,
      value: label,
      count: 0,
      isRefined: false,
    }
  })
)

const Header = ({ searchResults }: StateResultsProvided) => {
  if (!searchResults) { return null }

  const { nbHits } = searchResults

  return (
    <p className="my-5 result-stats">
      Showing {nbHits} result{nbHits === 1 ? '' : 's'}
    </p>
  )
}
const ConnectedStateHeader = connectStateResults(Header)

const autosuggestClient = algoliasearch(
  WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering',
  WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY || 'server-rendering')

const RegenSiteSearch: React.FunctionComponent<IProps> = ({ blogSource }) => {
  return (
    <AlgoliaSearch
      indexName={blogSource.index}
      widgetName="regen-site-search"
      storeStateInQuery={true}
    >
      <Configure hitsPerPage={6} />

      <div className="col-content-container col-content-offset px-0">
        <SearchBox
          defaultIndex={blogSource.index}
          autosuggestClient={autosuggestClient}
          reset={<i className="material-icons">search</i>}
          searchAsYouType={false} />

        <ConnectedStateHeader />

        <div className="mb-4">
          <RefinementList
            attribute="regen_result_type"
            operator="or"
            transformItems={transformRefinementItems}
            defaultRefinement={staticRefinements} />
        </div>
      </div>

      <ConnectedSearchResults
        ResultBlock={ResultBlock}
        containerClassName="col-content-container px-0 mx-auto" />
    </AlgoliaSearch>
  )
}

const ConnectedRegenSiteSearch = withTranslations(RegenSiteSearch)
export default ConnectedRegenSiteSearch
