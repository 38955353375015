import * as React from 'react'
import { HitsProvided } from 'react-instantsearch-core'
import { connectHits } from 'react-instantsearch-dom'
import { IPorchMessageHit } from '../../models/algolia/messages_production'
import { PorchCardsCarouselItems } from '../porch-cards-carousel-items'

export type IPorchCardsCarouselResultsProps = HitsProvided<IPorchMessageHit>

export const PorchCardsCarouselResults: React.FC<IPorchCardsCarouselResultsProps> = ({ hits }) => {
  return <div>
    <PorchCardsCarouselItems navPosition="top" items={hits} />
  </div>
}

export const ConnectedPorchCardsCarouselResults = connectHits(PorchCardsCarouselResults)
