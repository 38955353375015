import { createContext, useContext } from 'react'

export interface ICarouselContext {
  currentSlide: number
  play: () => void
  pause: () => void
  goToNextSlide: () => void
  goToPrevSlide: () => void
  goToSlide: (slideIndex: number) => void
}

export const CarouselContext = createContext<ICarouselContext>({} as ICarouselContext)

export const useCarousel = () => useContext(CarouselContext)
