import * as React from 'react'
import { IVideo } from './index'
import Thumbnail from './thumbnail'

interface IProps {
  currentVideoIndex: number
  videos: IVideo[]
  onSelect: (newIndex: number) => void
  windowSize?: number
}

const ThumbnailList: React.FunctionComponent<IProps> = ({
  currentVideoIndex,
  onSelect,
  videos,
  windowSize = 4,
}) => {
  const [windowStart, setWindowStart] = React.useState<number>(0)

  const canMove = (direction: 'right' | 'left') => {
    if (direction == 'right') {
      return windowStart + windowSize < videos.length
    } else {
      return windowStart > 0
    }
  }

  const moveWindow = React.useCallback((direction: 'right' | 'left') => {
    if (direction == 'right') {
      if (windowStart + windowSize < videos.length) {
        setWindowStart(windowStart + windowSize)
      }
    } else {
      if (windowStart - windowSize >= 0) {
        setWindowStart(windowStart - windowSize)
      } else {
        setWindowStart(0)
      }
    }
  }, [windowStart, videos, setWindowStart])

  const videoWindow = videos.slice(windowStart, windowStart + windowSize)

  return (
    <div className="thumbnail-list d-flex justify-content-between align-items-center">
      <div>
        <button className={`control-prev ${!canMove('left') ? 'disabled' : ''}`}
          role="button"
          disabled={!canMove('left')}
          onClick={() => moveWindow('left')} >
          <span className="control-prev-icon" aria-hidden="true" />
          <span className="sr-only">Previous</span>
        </button>
      </div>
      <div className="d-flex flex-grow-1 justify-content-center">
        {videoWindow.map((video, i) => (
          <div className="thumbnail-container" style={{ width: `${100 / windowSize}%` }} key={i}>
            <Thumbnail
              imageSrc={video.thumbnailImageSrc}
              onSelect={() => onSelect(i + windowStart)}
              active={i + windowStart === currentVideoIndex}
            />
          </div>
        ))}
      </div>
      <div>
        <button className={`control-next ${!canMove('right') ? 'disabled' : ''}`}
          role="button"
          disabled={!canMove('right')}
          onClick={() => moveWindow('right')} >
          <span className="control-next-icon" aria-hidden="true" />
          <span className="sr-only">Next</span>
        </button>
      </div>
    </div>
  )
}

export default ThumbnailList
