import * as React from 'react'
import {
  connectInfiniteHits,
  InfiniteHitsProvided,
} from 'react-instantsearch-core'
import CardBlock from './card-block'

interface IProps {
  ResultBlock?: typeof CardBlock
  containerClassName?: string
}

const SearchResults: React.FunctionComponent<IProps & InfiniteHitsProvided> = ({
  ResultBlock = CardBlock,
  containerClassName = 'col-content-container mx-auto',
  hits,
  refineNext,
  hasMore,
}) => (
  <div className={containerClassName}>
    <div className="row">
      <div className="col-12">
        <ResultBlock hits={hits} />
      </div>
    </div>
    {hasMore && (
      <button
        className="d-block btn btn-info mx-auto mt-5"
        onClick={refineNext}
      >
        Load More Results
      </button>
    )}
  </div>
)

const ConnectedSearchResults = connectInfiniteHits(SearchResults)

export default ConnectedSearchResults
