import LocationMap from '@watermarkchurch/location-map'
import React from 'react'
import WCC from 'wcc'
import aa, {InsightsClient} from '../lib/components/algolia-search/insights'
import { ListItem } from '../lib/components/location-map/list-item'

import connectGoogleMaps from '../lib/connectors/googleMaps'
import { ITranslationsProvided, withTranslations } from '../lib/connectors/translation'

interface IProps {
  domainObject: {
    id: string;
    title: string;
    type: string;
  },
  indexName: string

  virtualEventsIndex?: string
  includeVirtualEvents?: boolean
}

const ConnectedLocationMap = connectGoogleMaps(LocationMap)

export default withTranslations(({t, translationsAt: _, ...rest}: IProps & ITranslationsProvided) =>
  <ConnectedLocationMap {...rest}
    algoliaApiKey={WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY || 'server-rendering'}
    algoliaAppId={WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering'}
    translate={t}
    aa={aa as InsightsClient}

    ListItem={ListItem} />,
)
