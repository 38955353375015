import * as React from 'react'
import { findDOMNode } from 'react-dom'

import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import { Stringable } from '../../util'
import LiveStreamCountdown from './live-stream-countdown'

interface IProps {
    labelText?: string
    backgroundImageUrl?: string
    liveStreamId?: Stringable
    timestamp?: string
}

interface IState {
    expired: boolean
}

class CountdownTimerWrapper extends React.Component<IProps & ITranslationsProvided, IState> {
  private sectionContainerNode?: JQuery<Node>
  constructor(props: CountdownTimerWrapper['props'], context?: any) {
    super(props, context)

    this.state = { expired: false }
  }

  public async componentDidMount() {
    // TODO: use a react ref
    // eslint-disable-next-line react/no-find-dom-node
    const node = findDOMNode(this)

    if (node && node.parentNode) {
      this.sectionContainerNode = $(node.parentNode).parents('section')
      this.displaySections()
    }
  }

  public expire = () => {
    this.setState({ expired: true })
    this.displaySections()
  }

  public refresh = () => {
    this.setState({ expired: false })
    this.displaySections()
  }

  public render() {
    const { backgroundImageUrl, liveStreamId, labelText, t } = this.props
    const styles = {
      backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : 'none',
      backgroundSize: 'cover',
    }
    if (liveStreamId) {
      return <div className="embed" style={styles}>
        <LiveStreamCountdown
          // note: This cannot change w/ 'expired' b/c we get into a remounting loop
          key={`live-stream-countdown`}
          id={liveStreamId}
          backgroundImageUrl={backgroundImageUrl}
          labelText={labelText || t('labelText')}
          onRefresh={this.refresh}
          onExpiry={this.expire}
        />
      </div>
    }
  }

  private displaySections() {
    if (!this.sectionContainerNode) { return }

    $('.async-section-container', this.sectionContainerNode).addClass('hidden')
    $(this.sectionContainerNode)
      .find(`.js-countdown-expired-${this.state.expired}`)
      .removeClass('hidden')
  }
}

export default withTranslations(
  CountdownTimerWrapper,
  {
    labelText: 'Streaming live Tuesdays at 7PM (CST)',
  },
)
