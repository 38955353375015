import { HiddenFacets, IRefinementDefinition, SearchBox } from '@watermarkchurch/react-instantsearch-components'
import algoliasearch from 'algoliasearch'
import * as React from 'react'
import { Configure, connectStats } from 'react-instantsearch-dom'
import WCC from 'wcc'
import { ITranslationsProvided, withTranslations } from '../../connectors/translation'
import AlgoliaSearch from '../algolia-search'
import { SearchFacet } from '../porch-search-facet'
import ConnectedSearchResults from './search-results'

interface IProps extends ITranslationsProvided {
  blogSource: BlogSource,
  acceptedTags: Array<{ key: string, title: string }> | undefined
  translations: {
    noResults: string,
    searchReset: string,
    searchBox: {
      placeholder: string,
    },
  },
  showFacets?: boolean,
  header?: string,
  limit?: 9,
  showCount?: boolean,
  showHasMore?: boolean
}

const HitCount = ({ nbHits }: any) => <span className="porch-blog-search__hits-count ml-3">({nbHits})</span>

const HitCountStat = connectStats(HitCount)

type BlogSource = IAlgoliaBlogSource

interface IAlgoliaBlogSource {
  source: 'Algolia'
  property: string
  index: string
  facetFilters?: IRefinementDefinition
  operator?: 'or' | 'and'
}

const autosuggestClient = algoliasearch(
  WCC.CONSTANTS?.ALGOLIA_APP_ID || 'server-rendering',
  WCC.CONSTANTS?.ALGOLIA_SEARCH_API_KEY  || 'server-rendering')

export class PorchBlogSearch extends React.Component<IProps> {
  public render() {
    const { blogSource, acceptedTags, showFacets, header, limit, showCount, showHasMore = false } = this.props

    return (
      <AlgoliaSearch
        indexName={blogSource.index}
        widgetName="blog-search"
        storeStateInQuery={true}
      >
        <HiddenFacets hiddenFacets={{ 'publishedProperties.key': blogSource.property }} />
        {blogSource.facetFilters &&
          <HiddenFacets hiddenFacets={blogSource.facetFilters}
            operator={blogSource.operator} />}
        <div className="container">
          <div className="row mb-4">
            <div className="col-section-heading">
              {header && <h2 className="section-title">
                {header}
                {showCount ? (
                  <HitCountStat />
                ) : null}
              </h2>}
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-4 mb-4">
              <SearchBox defaultIndex={blogSource.index}
                autosuggestClient={autosuggestClient}
                className="narrow porch-blog-search__search-box"
                searchAsYouType={false}
                translations={this.props.translationsAt('searchBox') as { [key: string]: string }} />
            </div>
            {showFacets && (
              <>
                <div className="col-12 offset-md-4 col-md-2 mb-4">
                  <SearchFacet
                    attribute="date_year"
                    attributeTitle="Year"
                    sort="desc"
                  />
                </div>
                <div className="col-12 col-md-2 mb-4">
                  <SearchFacet
                    attribute="tags.key"
                    limit={15}
                    attributeTitle="Topics"
                    acceptedValues={acceptedTags}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <div className="container pt-4">
          <Configure hitsPerPage={limit || 9} />
          <ConnectedSearchResults showHasMore={showHasMore} />
        </div>
      </AlgoliaSearch>
    )
  }
}

const ConnectedPorchBlogSearch = withTranslations(PorchBlogSearch)
export default ConnectedPorchBlogSearch
