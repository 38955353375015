import * as React from 'react'

interface IProps {
  onSelect: () => void
  imageSrc: string
  active: boolean
}

const Thumbnail: React.FunctionComponent<IProps> = ({ onSelect, imageSrc, active }) => {
  return (
    <button
      className={`thumbnail embed-responsive embed-responsive-16by9 ${active ? 'active' : null}`}
      onClick={onSelect}
    >
      <img alt='' className="embed-responsive-item" src={imageSrc} />
    </button>
  )
}
export default Thumbnail
