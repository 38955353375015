import * as React from 'react'
import { Hit } from 'react-instantsearch-core'

import { ICompositePost } from '../../models/algolia/composite'

interface IProps {
  hit: Hit<ICompositePost>
}

const ResourceCard: React.FunctionComponent<IProps> = ({ hit }) => {
  const {
    slug,
    title,
    thumbnail_image_url,
    hero_image_url,
    tags,
    author,
    time_to_read,
  } = hit.Post

  return (
    <div className="card regen-resource-preview-card mb-4">
      <a href={slug} className="card-img-top">
        <img alt='' className="embed-responsive" src={thumbnail_image_url || hero_image_url || undefined} />
      </a>
      <div className="card-body">
        <p className="overline">
          {tags.map((tag) =>
            <a key={tag.key}
              className="tag"
              href={`/search?query=${encodeURIComponent(tag.title)}`}>{tag.title}
            </a>)}
        </p>

        <p className="card-title">{title}</p>

        <div className="card-text">
          <div className="formatted-content">
            <p>
              {[
                author?.full_name,
                `${Math.ceil(time_to_read)} min`,
              ].filter(Boolean).join(' • ')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResourceCard
