import * as React from 'react'

interface IProps {
  iframeSrc: string
}

const VideoPlayer: React.FunctionComponent<IProps> = ({ iframeSrc }) => {
  return (
    <div className="video-player embed-responsive embed-responsive-16by9">
      <iframe
        className="embed-responsive-item"
        allow="autoplay; fullscreen; picture-in-picture"
        allowFullScreen={true}
        src={iframeSrc} />
    </div>
  )
}

export default VideoPlayer
