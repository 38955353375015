import * as React from 'react'
import { HitsProvided } from 'react-instantsearch-core'
import { connectHits } from 'react-instantsearch-dom'
import { IPorchMessageHit, messageHref } from '../../models/algolia/messages_production'

export type IPorchLatestMessageResultsProps = HitsProvided<IPorchMessageHit>

export const PorchLatestMessageResults: React.FC<IPorchLatestMessageResultsProps> = ({ hits }) => {
  return <div>
    {hits.map((hit) => {
      const thumb = hit.thumbnail_url?.replace('http://', 'https://')

      return <div key={hit.objectID} className="row">
        <div className="col-12 col-lg-5 porch-latest-message__content">
          <div className="porch-latest-message__message">
            <div className="overline">Latest Message</div>
            <h2 className="porch-latest-message__title">{hit.title}</h2>
            <p>{hit.description}</p>
            <a href="/messages" className="btn btn-info porch-latest-message__btn">
              See All Messages
            </a>
          </div>
        </div>
        <div className="col-12 col-lg-7">
          <div className="card">
            <a href={messageHref(hit)} className="card-img-top stretched-link">
              <div className="thumbnail">
                <img className="thumbnail__image" src={thumb} alt={hit.title} />
                <div className="thumbnail__overlay">
                  <span className="thumbnail__text">Watch</span>
                  <svg className="thumbnail__circle" height="100" width="100">
                    <circle cx="50" cy="50" r="40" />
                  </svg>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    })}
  </div>
}

export const ConnectedPorchLatestMessageResults = connectHits(PorchLatestMessageResults)
