import * as React from 'react'
import { IVideo } from './index'
import Thumbnail from './thumbnail'

interface IProps {
  currentVideoIndex: number
  videos: IVideo[]
  onSelect: (newIndex: number) => void
  windowSize?: number
}

const MobileThumbnailList: React.FunctionComponent<IProps> = ({
  currentVideoIndex,
  onSelect,
  videos,
  windowSize = 4,
}) => {
  return (
    <div className="mobile-thumbnail-list d-flex">
      {videos.map((video, i) => (
        <div className="thumbnail-container flex-shrink-0" style={{ width: `${100 / windowSize + 5}%` }} key={i}>
          <Thumbnail
            imageSrc={video.thumbnailImageSrc}
            onSelect={() => onSelect(i)}
            active={i === currentVideoIndex}
          />
        </div>
      ))}
    </div>
  )
}

export default MobileThumbnailList
