import React, { FunctionComponent, useCallback, useMemo, useState } from 'react'

import SearchFilters from './search-filters'

interface IStruggle {
  title: string
  description: string
  url: string
  style: string
  tags: string[]
}

interface IProps {
  struggles: IStruggle[]
}

const RegenStrugglesList: FunctionComponent<IProps> = ({ struggles }) => {
  const [search, setSearch] = useState<string>('')
  const handleSearchChange = useCallback((newValue: string) => {
    setSearch(newValue)
  }, [])
  const filteredStruggles: IStruggle[] =
    useMemo(
      () => struggles.filter((s) => s.title.toLowerCase().includes(search.toLowerCase())),
      [search, struggles],
    )

  return (
    <div className="col-content-container mx-auto p-0 regen-struggles-list">
      <SearchFilters onSearchChange={handleSearchChange} />
      <div className="row">
        {filteredStruggles.map((struggle, index) => (
          <div className="col col-12 col-md-4" key={index}>
            <div className={`card ${struggle.style}`}>
              <div className="card-body">
                <h4 className="card-title">{struggle.title}</h4>
                <div className="card-text">
                  <div className="formatted-content">
                    <p>{struggle.description}</p>
                  </div>
                </div>
                <a href={struggle.url} className="btn btn-info">Learn More</a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RegenStrugglesList
